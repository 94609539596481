



























import {
    Component, Vue, Prop,
} from 'vue-property-decorator';
import WaitTimeData from '@/services/waitTimeData';
import DataType from '@/models/dataType';
import MiniLine from './mini-line.vue';

@Component({ components: { MiniLine } })
export default class AreaStatsTable extends Vue {
    @Prop()
    ccgCodes!: Array<{ areaCode: string; areaName: string; ranking: number; last?: boolean; first?: boolean; colour: string }>;

    @Prop()
    regionLevel!: boolean;

    @Prop()
    smallChart!: boolean;

    @Prop()
    max!: number;

    @Prop()
    min!: number;

    waitTimeData: WaitTimeData | null = null;

    async mounted () {
        this.waitTimeData = await WaitTimeData.get();
    }

    get loaded () {
        return this.waitTimeData?.loaded;
    }

    getColour (val: number) {
        if (!this.waitTimeData?.map) return '#444';
        return this.waitTimeData.map.getValueColour(val, this.selectedArea ? undefined : this.regionExtents);
    }

    get regionExtents () {
        const max = this.max || Math.max(...this.tableData.map((a) => a.medianWaitTime || 0));
        const min = this.min || Math.min(...this.tableData.map((a) => a.medianWaitTime || 0));
        return () => (this.isHigherBetter ? [min, min + (max - min) / 2, max] : [max, min + (max - min) / 2, min]) as [number, number, number];
    }

    get selectedArea () {
        return this.waitTimeData!.selectedArea;
    }

    selectArea (area: { areaCode: string; areaName: string }) {
        if (this.regionLevel) {
            this.waitTimeData?.map!.selectRegions([area.areaName]);
        } else {
            const region = this.waitTimeData!.getRegionNameFromCode(area.areaCode);
            if (region !== this.selectedRegion) this.waitTimeData?.map!.selectRegions([region]);
            this.$nextTick(() => {
                this.waitTimeData?.map!.selectAreas(this.waitTimeData.getareaCodesFromCcgCode(area.areaCode));
            });
        }
    }

    get selectedRegion () {
        return this.waitTimeData!.selectedRegion;
    }

    get selectedDate () {
        return this.waitTimeData!.selectedDate || '';
    }

    get isHigherBetter () {
        return this.waitTimeData?.metrics.find((a) => a.value === this.waitTimeData?.selectedMetric)?.higherBetter;
    }

    get selectedTreatment () {
        return this.waitTimeData!.selectedTreament;
    }

    get selectedMetric () {
        return this.waitTimeData!.selectedMetric;
    }

    get selectedMetricName () {
        return this.waitTimeData?.metrics.find((a) => a.value === this.selectedMetric)?.name;
    }

    get previousMonth () {
        if (!this.waitTimeData) return null;
        const index = this.waitTimeData.dates.indexOf(this.selectedDate);
        if (index === 0) return null;
        return this.waitTimeData?.dates[index - 1];
    }

    getRanking (areaCode: string) {
        if (this.regionLevel) return this.waitTimeData?.getRankingForRegion(areaCode);
        return this.waitTimeData?.getRankingForCcg(areaCode);
    }

    get filteredCcg () {
        return this.ccgCodes.filter((area, index, self) => area.areaCode && index === self.findIndex((t) => (
            t.areaCode === area.areaCode && t.areaCode === area.areaCode
        )));
    }

    get tableData (): Array<{areaCode: string; colour: string; ranking: number; areaName?: string; medianWaitTime: number | undefined | null; medianWaitTimeData: Array<number | undefined>}> {
        if (this.regionLevel) {
            return this.filteredCcg.filter((a) => a.areaCode).map((area) => ({
                areaCode: area.areaCode,
                colour: area.colour,
                ranking: area.ranking,
                areaName: this.waitTimeData?.getRegionNameFromRegionCode(area.areaCode),
                medianWaitTime: this.waitTimeData?.getAllRegionDataForDateGroupedByRegion(this.selectedDate, this.selectedMetric, this.selectedTreatment, DataType.Incomplete)[area.areaCode],
                medianWaitTimeData: this.waitTimeData?.getTotalsForAllDatesForRegion(this.waitTimeData.getRegionNameFromRegionCode(area.areaCode), this.selectedMetric, this.selectedTreatment, DataType.Incomplete) || [],
            }));
        }
        return this.filteredCcg.filter((a) => a.areaCode).map((area) => ({
            first: area.first,
            last: area.last,
            areaCode: area.areaCode,
            colour: area.colour,
            ranking: area.ranking,
            areaName: this.waitTimeData?.getAreaNameFromCcgCode(area.areaCode),
            medianWaitTime: this.waitTimeData?.getAllAreaDataForDateGroupedByCcg(this.selectedDate, this.selectedMetric, this.selectedTreatment, DataType.Incomplete)[area.areaCode],
            previousMedianWaitTime: this.previousMonth ? this.waitTimeData?.getAllAreaDataForDateGroupedByCcg(this.previousMonth, this.selectedMetric, this.selectedTreatment, DataType.Incomplete)[area.areaCode] : '',
            medianWaitTimeData: this.waitTimeData?.getTotalsForAllDatesForArea(area.areaCode, this.selectedMetric, this.selectedTreatment, DataType.Incomplete, true) || [],
        }));
    }

    formatNumber (number?: number) {
        if (number === null || number === undefined) return '';
        return number.toLocaleString();
    }
}
