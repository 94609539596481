interface Gradient {
    hue: number;

    saturation: number;

    lightness: number;
}

export default Gradient;

export enum GradientType {
    NoGradient,
    GradientOnEmpty,
    Gradient
}
