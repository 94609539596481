













import {
    Component, Vue,
} from 'vue-property-decorator';
import WaitTimeData from '@/services/waitTimeData';
import AreaStatsTable from '@/components/chart/area-stats-table.vue';

@Component({ components: { AreaStatsTable } })
export default class RankingsTable extends Vue {
    waitTimeData: WaitTimeData | null = null;

    async mounted () {
        this.waitTimeData = await WaitTimeData.get();
    }

    get loaded () {
        return this.waitTimeData?.loaded && !this.waitTimeData.loading;
    }

    get selectedTreatment () {
        return this.waitTimeData!.selectedTreament;
    }

    get selectedDate () {
        return this.waitTimeData!.selectedDate;
    }

    get selectedMetric () {
        return this.waitTimeData!.selectedMetric;
    }

    get selectedAreaName () {
        return this.selectedArea ? this.waitTimeData?.getAreaNameFromAreaCode(this.selectedArea) : this.selectedRegion;
    }

    get selectedArea () {
        return this.waitTimeData!.selectedArea!;
    }

    get selectedRegion () {
        return this.waitTimeData!.selectedRegion;
    }

    get areaData () {
        return this.waitTimeData!.localAuthorities[this.selectedArea];
    }

    get rankingsTable () {
        const areas = [];
        if (!this.selectedArea) {
            return this.rankings.map(((a, i) => ({ areaCode: a.area, ranking: i + 1 })));
        }
        const r = Math.min(Math.max(this.ranking - 5, 0), this.areaCount - 9);
        let count = 10;
        if (this.ranking > 5) {
            count -= 1;
            areas.push({
                areaCode: this.rankings[0].area, ranking: 1, first: true,
            });
        }
        for (let i = r; i < r + count; i += 1) {
            if (this.rankings && this.rankings[i]) {
                areas.push({ areaCode: this.rankings[i].area, ranking: i + 1 });
            }
        }
        if (this.rankings.length && areas[areas.length - 1].areaCode !== this.rankings[this.rankings.length - 1].area) {
            areas.splice(areas.length - 1, 1);
            areas.push({
                areaCode: this.rankings[this.rankings.length - 1].area,
                ranking: this.rankings.length,
                last: true,
            });
        }
        return areas;
    }

    get areaCount () {
        if (!this.selectedArea) return this.waitTimeData?.getRegionRankings.length || 0;
        return this.waitTimeData?.getCcgRankings.length || 0;
    }

    get ranking (): number {
        return this.waitTimeData?.ranking || 0;
    }

    get rankings () {
        return this.waitTimeData!.rankings;
    }
}
