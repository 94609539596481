







































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import WaitTimeData from '@/services/waitTimeData';
import ScatterChartData from '@/models/scatterChartData';
import { GradientType } from '@/models/gradient';
import * as d3 from 'd3';
import Dropdown from '@/components/dropdown.vue';
import TreatmentType from '@/models/treatmentType';
import DataType from '@/models/dataType';
import CcgCsvData from '@/models/ccgCsvData';
import ScatterChart from '../components/chart/scatterChart';

@Component({
    components: {
        Dropdown,
    },
    props: {
        path: String,
    },
})
export default class Analysis extends Vue {
    scatterChart: ScatterChart | null = null;

    waitTimeData: WaitTimeData | null = null;

    expanded = false;

    xAxis = 'imdAverageScore';

    xAxisYear = '2018';

    xAxisDomainData: { [area: string]: number } = {};

    xAxisDomainDataCompare: { [area: string]: number } = {};

    yAxis = 'averageMedianWaitingTimeInWeeks';

    yAxisYear = '2018';

    viewType = 'scatter';

    xAxisTotal = 'value';

    yAxisTotal = 'value';

    dotSize = 'default';

    yAxisDomainData: { [area: string]: number } = {};

    yAxisDomainDataCompare: { [area: string]: number } = {};

    isCollapsed = true;

    hoveredY = '';

    hoveredX = '';

    treatmentType: TreatmentType = TreatmentType['All specialties (total)'];

    mounted () {
        WaitTimeData.get().then(async (waitTimeData) => {
            this.waitTimeData = waitTimeData;
            await this.waitTimeData.loadLatestData();
            this.scatterChart = new ScatterChart(waitTimeData);
            this.scatterChart.initalise(
                this.$refs.chart as HTMLElement,
                this.$refs.tooltip as HTMLElement,
                this.chartData,
                this.xAxisTitle,
                this.yAxisTitle,
            );
        });
    }

    @Watch('xAxis')
    @Watch('treatmentType')
    @Watch('xAxisYear')
    async xAxisChanged () {
        if (!this.scatterChart) return;
        this.scatterChart.changeData(
            this.chartData,
            this.xAxisTitle,
            this.yAxisTitle,
            this.xAxisArrowText,
            this.yAxisArrowText,
        );
    }

    @Watch('xAxisTotal')
    @Watch('dotSize')
    async xAxisTotalChanged () {
        if (!this.scatterChart) return;
        this.scatterChart.changeData(
            this.chartData,
            this.xAxisTitle,
            this.yAxisTitle,
            this.xAxisArrowText,
            this.yAxisArrowText,
        );
    }

    @Watch('yAxisTotal')
    async yAxisTotalChanged () {
        if (!this.scatterChart) return;
        this.scatterChart.changeData(
            this.chartData,
            this.xAxisTitle,
            this.yAxisTitle,
            this.xAxisArrowText,
            this.yAxisArrowText,
        );
    }

    get xAxisTitle () {
        return `${this.axisTitle(this.xAxis)}`;
    }

    get yAxisTitle () {
        return `${this.axisTitle(this.yAxis)}`;
    }

    xIsHovered (metric: string) {
        this.hoveredX = metric;
    }

    yIsHovered (metric: string) {
        this.hoveredY = metric;
    }

    @Watch('yAxis')
    @Watch('yAxisYear')
    async yAxisChanged () {
        if (!this.scatterChart) return;
        this.scatterChart.changeData(
            this.chartData,
            this.xAxisTitle,
            this.yAxisTitle,
            this.xAxisArrowText,
            this.yAxisArrowText,
        );
    }

    get xAxisArrowText () {
        if (this.xAxis === 'imdAverageScore') return 'More deprived area';
        if (this.xAxis === 'imdDecile') return 'Less deprived area';
        return '';
    }

    get yAxisArrowText () {
        if (this.yAxis === 'imdAverageScore') return 'More deprived area';
        if (this.yAxis === 'imdDecile') return 'Less deprived area';
        return '';
    }

    get axisOptions () {
        return [
            {
                value: 'population', name: 'Population', icon: 'user-friends', color: '#2567c7', description: 'The number of people in each CCG.',
            },
            {
                value: 'medianAge', name: 'Median age', icon: 'child', color: '#7ebed8', description: 'Median age of the CCG population, where half the CCG population are younger than this age and half the CCG population are older.',
            },
            {
                value: 'imdDecile', name: 'IMD Decile', icon: 'badge-percent', color: '#e69721', description: 'IMD Score for each CCG is ranked and divided into ten approximately equal groups (deciles), where decile 1 is the most deprived and 10 is the least deprived. ',
            },
            {
                value: 'imdAverageScore', name: 'Deprivation score', icon: 'money-bill-wave', color: '#e69721', description: 'The Index of Multiple Deprivation (IMD) score is a population weighted measure of the relative deprivation of the CCG, accounting for factors such as income, crime, education, health, employment, living environment and barriers to housing and services.',
            },
            ...this.waitTimeData!.getMetrics(this.treatmentType),
            // {
            //     value: 'ruralUrban', name: 'Rural Urban Index', icon: 'city', color: '#8a8a8a',
            // },

        ];
    }

    isReverseAxis (axis: string) {
        return axis === 'populationDensity' || axis === 'medianAge' || axis === 'imdAverageScore';
    }

    get isMobile () {
        return window.innerWidth < 900;
    }

    axisTitle (value: string) {
        switch (value) {
        case 'medianAge': return 'Median age';
        case 'imdDecile': return 'IMD Decile';
        case 'imdAverageScore': return 'Deprivation score';
        case 'percentage': return 'Cumulative COVID-19 infections (%)';
        case 'population': return 'Population';
        default:
            return this.waitTimeData?.metrics.find((a) => a.value === value)?.name || value;
        }
    }

    get treatmentTypes () {
        return Object.keys(TreatmentType).sort((a, b) => (a > b ? 1 : -1)).map((a) => ({ name: a, value: TreatmentType[a as keyof typeof TreatmentType] }));
    }

    get gradientType () {
        return GradientType.NoGradient;
    }

    get latestDate () {
        if (!this.waitTimeData) return '';
        return 'Nov-21';
    }

    getAxis (key: string | keyof CcgCsvData, axis: string) {
        const ccgData = this.getCcgDataForAxis(axis);
        return ccgData![key];
    }

    getCcgDataForAxis (axis: string) {
        return this.waitTimeData?.getAllAreaDataForDateGroupedByCcg(this.latestDate, axis as keyof CcgCsvData, this.treatmentType, DataType.Incomplete, false);
    }

    get sizeExents () {
        if (!this.waitTimeData) return [0, 0];
        if (this.dotSize === 'population') {
            const populationsByCcg = Object.values(this.getCcgDataForAxis('population') ?? {});
            return d3.extent(populationsByCcg.filter((a) => a !== null) as Array<number>) as unknown as [number, number];
        }
        if (this.dotSize === 'imdAverageScore') {
            const imdScoreByCcg = Object.values(this.getCcgDataForAxis('imdAverageScore') ?? {});
            return d3.extent(imdScoreByCcg.filter((a) => a !== null) as Array<number>) as unknown as [number, number];
        }
        return [0, 0];
    }

    getSize (areaCode: string) {
        if (!this.waitTimeData) return 7;
        if (this.dotSize === 'population') {
            const pop = this.getAxis(areaCode, 'population');
            return ((pop || 0) / this.sizeExents[1]) * 20;
        }
        if (this.dotSize === 'imdAverageScore') {
            const score = this.getAxis(areaCode, 'imdAverageScore');
            return ((score || 0) / this.sizeExents[1]) * 14;
        }
        return 7;
    }

    get chartData () {
        const data: ScatterChartData[] = [];
        if (!this.waitTimeData || this.axisOptions.filter((x) => x.value === this.xAxis).length === 0 || this.axisOptions.filter((x) => x.value === this.yAxis).length === 0) return data;

        this.waitTimeData.ccgCodes.forEach((d) => {
            const xValue = this.getAxis(d, this.xAxis);
            const yValue = this.getAxis(d, this.yAxis);

            // if (xValue === null || yValue === null) return;

            data.push({
                name: d,
                x: xValue as number,
                y: yValue as number,
                category: this.waitTimeData!.getRegionNameFromCode(d),
                size: this.getSize(d),
            });
        });

        return data;
    }
}
