






import { Chart, TooltipFormatterContextObject } from 'highcharts';

import {
    Component, Vue, Prop, Watch,
} from 'vue-property-decorator';
import WaitTimeData from '@/services/waitTimeData';
import TreatmentType from '@/models/treatmentType';
import CcgCsvData from '@/models/ccgCsvData';
import dropdown from '@/components/dropdown.vue';

@Component({ components: { dropdown } })
export default class TreatmentWaitingTimes extends Vue {
    @Prop()
    metric!: keyof CcgCsvData;

    @Prop()
    title!: keyof CcgCsvData;

    @Prop()
    selectedTreatment!: TreatmentType;

    @Prop()
    selectedArea!: string;

    @Prop()
    selectedRegion!: string;

    @Prop()
    selectedDemographic!: string;

    waitTimeData: WaitTimeData | null = null;

    hasClicked = false;

    async mounted () {
        this.waitTimeData = await WaitTimeData.get();
        this.$nextTick(() => {
            this.selectYear();
            this.chart.reflow();
        });
    }

    get selectedValue () {
        return this.getDataForTreatmentType(this.selectedTreatment);
    }

    get chartData () {
        if (!this.waitTimeData || !this.waitTimeData!.loaded) return [];

        const value = this.getDataForTreatmentType(this.selectedTreatment);
        return {
            name: this.selectedDemographic,
            // size: '100%',
            // innerSize: '30%',
            data: this.demographics[this.selectedDemographic].map(((a) => ({
                name: a.name,
                y: (value ?? 0) * a.percentage + (a.percentage * Math.random()),
            }))),
        };
    }

    gotoPreviousDate () {
        this.$emit('areaChanged', this.newCcgAreas![0].value);
    }

    get demographics (): Record<string, Array<{ name: string; percentage: number }>> {
        return {
            Gender: [
                { name: 'Male', percentage: 0.48 },
                { name: 'Female', percentage: 0.52 },
            ],
            Ethnicity: [
                { name: 'Asian', percentage: 0.075 },
                { name: 'Black', percentage: 0.033 },
                { name: 'Mixed', percentage: 0.022 },
                { name: 'White', percentage: 0.86 },
                { name: 'Other', percentage: 0.01 },
            ],
            Deprivation: [
                { name: 'IMD Decile: 1', percentage: 0.14 },
                { name: 'IMD Decile: 2', percentage: 0.12 },
                { name: 'IMD Decile: 3', percentage: 0.11 },
                { name: 'IMD Decile: 4', percentage: 0.10 },
                { name: 'IMD Decile: 5', percentage: 0.10 },
                { name: 'IMD Decile: 6', percentage: 0.10 },
                { name: 'IMD Decile: 7', percentage: 0.09 },
                { name: 'IMD Decile: 8', percentage: 0.09 },
                { name: 'IMD Decile: 9', percentage: 0.08 },
                { name: 'IMD Decile: 10', percentage: 0.07 },
            ],
            Age: [
                { name: '0-4', percentage: 0.042 },
                { name: '5-9', percentage: 0.035 },
                { name: '10-14', percentage: 0.03 },
                { name: '15-17', percentage: 0.031 },
                { name: '18-24', percentage: 0.035 },
                { name: '25-29', percentage: 0.036 },
                { name: '30-34', percentage: 0.037 },
                { name: '35-39', percentage: 0.04 },
                { name: '40-44', percentage: 0.047 },
                { name: '45-49', percentage: 0.05 },
                { name: '50-54', percentage: 0.055 },
                { name: '55-59', percentage: 0.062 },
                { name: '60-64', percentage: 0.067 },
                { name: '65-69', percentage: 0.075 },
                { name: '70-74', percentage: 0.09 },
                { name: '75-79', percentage: 0.1 },
                { name: '80-84', percentage: 0.09 },
                { name: '84+', percentage: 0.08 },
            ],
        };
    }

    get categories () {
        return this.demographics[this.selectedDemographic].map((a) => a.name);
    }

    get isNewArea () {
        const newCcg = this.waitTimeData?.getNewCcgForCcg(this.selectedArea);
        return newCcg !== '' && newCcg === this.selectedArea;
    }

    get isNewMetric () {
        if (!this.waitTimeData) return false;
        return this.waitTimeData?.additionalTotalMetrics.filter((x) => x.value === this.metric).length > 0;
    }

    get isNewTreatmentType () {
        switch (this.selectedTreatment) {
        case '14':
        case '15':
        case '16':
        case '17':
        case '18':
            return true;
        default:
            return false;
        }
    }

    get hasNewMapping () {
        const newCcg = this.waitTimeData?.getNewCcgForCcg(this.selectedArea);
        return newCcg !== '' && newCcg !== this.selectedArea;
    }

    get newCcgAreas () {
        return this.waitTimeData?.getGroupedAreaCodes(this.selectedArea).map((area) => ({
            name: this.waitTimeData?.getAreaNameFromAreaCode(area, false),
            value: this.waitTimeData?.getCcgCodeForAreaCode(area, false),
            area,
        }));
    }

    get isMobile () {
        return window.innerWidth < 900;
    }

    getDataForTreatmentType (treatmentType: TreatmentType) {
        if (this.selectedArea) return this.waitTimeData?.getAllAreaDataForDateGroupedByCcg(this.selectedDate!, this.metric, treatmentType)[this.selectedArea];
        if (this.selectedRegion) return this.waitTimeData?.getAllRegionDataForDateGroupedByRegion(this.selectedDate!, this.metric, treatmentType)[this.waitTimeData.getRegionCodeFromRegionName(this.selectedRegion)];
        return this.waitTimeData?.getNationalTotalForDate(this.selectedDate!, this.metric, treatmentType);
    }

    seriesToggled (data: { name: string; visible: boolean }) {
        const series = this.chart.series.find((a) => a.name === data.name);
        if (series) series.setVisible(data.visible);
    }

    get isTotalMetric () {
        return this.waitTimeData?.metrics.find((a) => a.value === this.waitTimeData?.selectedMetric)?.total;
    }

    selectYear () {
        if (this.chart && this.chart.series.length && this.chart.series[0].points.length) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            const e: PointerEventObject = { target: this.chart.series[0].points[0] };
            if (this.chart.series[0].points[0].y != null) {
                const point = this.chart.series[0].points.find((a) => a.category === this.waitTimeData?.selectedDate);
                if (point) point.onMouseOver(e);
                else this.chart.series[0].points[0].onMouseOver(e);
            }
        }
        this.chart.tooltip.hide();
    }

    get selectedDate () {
        return this.waitTimeData?.selectedDate;
    }

    @Watch('selectedDate')
    selectedDateChanged () {
        if (!this.waitTimeData || !this.selectedDate) return;
        if (this.selectedDate !== this.waitTimeData.dates[this.waitTimeData.dates.length - 1]) {
            this.hasClicked = true;
        }
        if (this.chart) this.chart.redraw(false);
    }

    get chart () {
        return (this.$refs.chart as unknown as { chart: Chart}).chart;
    }

    get loading () {
        return this.waitTimeData?.loading;
    }

    @Watch('loading')
    @Watch('chartData')
    loadingChanged () {
        if (this.loading) return;
        this.$nextTick(() => {
            this.selectYear();
        });
    }

    get chartOptions () {
        const waitTimeData = this.waitTimeData;
        return {
            title: {
                text: '',
            },
            chart: {
                type: 'bar',
                backgroundColor: 'transparent',
                height: (window.innerHeight - 70 - 70 - 64) * 0.75 - 180,
            },
            xAxis: {
                categories: this.categories,
                labels: {
                    style: {
                        fontFamily: 'Roboto',
                    },
                },
                crosshair: {
                    enabled: true,
                    color: 'rgba(0, 163, 199, 0.15)',
                },
            },
            yAxis: {
                title: {
                    text: '',
                    style: {
                        fontFamily: 'Roboto',
                    },
                },
                labels: {
                    style: {
                        fontFamily: 'Roboto',
                    },
                    formatter (a: { value: number }): string {
                        return `${(a.value.toLocaleString())}`;
                    },
                },
            },
            plotOptions: {
                series: {
                    marker: {
                        enabled: false,
                    },
                },
            },
            tooltip: {
                shared: true,
                formatter (): string {
                    const self = this as unknown as TooltipFormatterContextObject;

                    return self.points!.reduce((s, point) => `${s}<br/><span style="color:${point.color}">\u25CF</span>
                        ${point.series.name}: <b>${Math.round(point.y).toLocaleString()}</b>`, `<i>${self.x}</i>`);
                },
                style: {
                    fontFamily: 'Roboto',
                    fontSize: '0.9rem',
                },
            },
            legend: {
                enabled: false,
            },
            credits: {
                enabled: false,
            },
            colors: ['#003087',
                '#919EA8',
                '#005EB8',
                '#666',
                '#99C7EB',
                '#DDE1E4',
                '#0072CE',
                '#999',
                '#005eb8',
                '#bbb',
                '#005eb8',
                '#005c5f',
                '#61093f',
                '#002e5f',
                '#fcb769',
                '#f48c89',
                '#92b1b6',
                '#33587f',
                '#33b6d2',
                '#5ad4a7',
                '#03628b',
                '#387e23',
                '#a53c13',
                '#9a9c00',
                '#198ea0',
                '#46a050',
                '#b2693b',
                '#b2a945',
                '#4aae89'],
            series: this.chartData || [],
        };
    }
}
