import * as d3 from 'd3';
import { NumberValue } from 'd3';
import Gradient from '../../models/gradient';
import WaitTimeData from '../../services/waitTimeData';
import ScatterChartData from '../../models/scatterChartData';

export default class ScatterChart {
    waitTimeData!: WaitTimeData;

    svg!: d3.Selection<HTMLElement, unknown, null, undefined>;

    circles!: d3.Selection<SVGGElement, unknown, null, undefined>;

    yAxis!: (a: d3.Selection<SVGElement, ScatterChartData, null, undefined>) => d3.Selection<SVGElement, ScatterChartData, null, undefined>;

    xAxis!: (a: d3.Selection<SVGElement, ScatterChartData, null, undefined>) => d3.Selection<SVGElement, ScatterChartData, null, undefined>;

    defs!: d3.Selection<SVGDefsElement, unknown, null, undefined>;

    directionGroupX!: d3.Selection<SVGGElement, unknown, null, undefined>;

    directionGroupY!: d3.Selection<SVGGElement, unknown, null, undefined>;

    constructor (waitTimeData: WaitTimeData) {
        this.waitTimeData = waitTimeData;
    }

    data: Array<ScatterChartData> = [];

    width = 954;

    height = 954;

    margin = ({
        top: 25, right: 20, bottom: 65, left: 65,
    });

    get x () {
        return d3.scaleLinear()
            .domain(d3.extent(this.data, (d) => d.x) as NumberValue[]).nice()
            .range([this.margin.left, this.width - this.margin.right]);
    }

    get y () {
        return d3.scaleLinear()
            .domain(d3.extent(this.data, (d) => d.y) as NumberValue[]).nice()
            .range([this.height - this.margin.bottom, this.margin.top]);
    }

    RGBToHSL (rgb: string): Gradient {
        const arr = rgb.replace('rgb(', '').replace(')', '').split(',');

        // Make r, g, and b fractions of 1
        let r = Number(arr[0]) / 255;
        let g = Number(arr[1]) / 255;
        let b = Number(arr[2]) / 255;

        if (rgb.startsWith('#')) {
            const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(rgb);
            if (!result) {
                return {
                    hue: 0, saturation: 0, lightness: 0,
                };
            }
            r = parseInt(result[1], 16) / 255;
            g = parseInt(result[2], 16) / 255;
            b = parseInt(result[3], 16) / 255;
        }

        // Find greatest and smallest channel values
        const cmin = Math.min(r, g, b);
        const cmax = Math.max(r, g, b);
        const delta = cmax - cmin;
        let h = 0;
        let s = 0;
        let l = 0;

        // Calculate hue
        // No difference
        if (delta === 0) {
            h = 0;
        } else if (cmax === r) {
            h = ((g - b) / delta) % 6;
        } else if (cmax === g) {
            h = (b - r) / delta + 2;
        } else h = (r - g) / delta + 4;

        h = Math.round(h * 60);

        // Make negative hues positive behind 360°
        if (h < 0) h += 360;

        // Calculate lightness
        l = (cmax + cmin) / 2;

        // Calculate saturation
        s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));

        // Multiply l and s by 100
        s = +(s * 100).toFixed(1);
        l = +(l * 100).toFixed(1);

        return { hue: h, saturation: s, lightness: l };
    }

    changeData (data: Array<ScatterChartData>, xAxisTitle: string, yAxisTitle: string, xArrowText: string, yArrowText: string) {
        this.data = data;
        this.x.domain(d3.extent(this.data, (d) => d.x) as NumberValue[]).nice()
            .range([this.margin.left, this.width - this.margin.right]);

        this.circles.selectAll('circle')
            .data(this.data)
            .transition()
            .duration(2000)
            .attr('r', (d) => d.size || 7)
            .attr('transform', (d) => `translate(${this.x((d as ScatterChartData).x)},${this.y((d as ScatterChartData).y)})`)
            .attr('opacity', (d) => (d.x === undefined || d.x === null || d.y === undefined || d.y === null ? 0 : 1));

        this.svg.select('.xAxis')
            .transition()
            .duration(1000)
            .call(this.xAxis as never);

        this.svg.select('.xLine')
            .transition()
            .duration(1000)
            .attr('x1', (d) => 0.5 + (this.x(d as NumberValue) || 0))
            .attr('x2', (d) => 0.5 + (this.x(d as NumberValue) || 0));

        this.svg.select('.yAxis')
            .transition()
            .duration(1000)
            .call(this.yAxis as never);

        this.svg.select('.yLine')
            .transition()
            .duration(1000)
            .attr('y1', (d) => 0.5 + (this.y(d as NumberValue) || 0))
            .attr('y2', (d) => 0.5 + (this.y(d as NumberValue) || 0));

        this.directionGroupX.attr('opacity', () => (xArrowText ? 1 : 0));
        this.directionGroupY.attr('opacity', () => (yArrowText ? 1 : 0));

        this.directionGroupX.select('text').text(xArrowText);
        this.directionGroupY.select('text').text(yArrowText);

        this.svg.select('.xAxisTitle').text(xAxisTitle);
        this.svg.select('.yAxisTitle').text(yAxisTitle);
    }

    createGradient (hue: Gradient, x: number, y: number, x2: number, y2: number) {
        const svgNode = this.svg.node();
        const svgNS = svgNode?.namespaceURI || '';
        const linearGradient = document.createElementNS(svgNS, 'linearGradient');
        const id = `g${Math.random()}`;
        linearGradient.setAttribute('id', id);
        linearGradient.setAttribute('x1', '100%');
        linearGradient.setAttribute('y1', '0%');
        linearGradient.setAttribute('x2', '0%');
        linearGradient.setAttribute('y2', '100%');
        const defsNode = this.defs.node();
        if (defsNode) {
            defsNode.appendChild(linearGradient);
        }

        const stopColour1 = document.createElementNS(svgNS, 'stop');
        stopColour1.setAttribute('stop-color', `hsl(${hue.hue % 360},${hue.saturation}%,${hue.lightness}%)`);
        stopColour1.setAttribute('offset', '0%');
        const stopColour2 = document.createElementNS(svgNS, 'stop');
        stopColour2.setAttribute('stop-color', `hsl(${(hue.hue + 20) % 360},${hue.saturation}%,${hue.lightness + 10}%)`);
        stopColour2.setAttribute('offset', '100%');
        linearGradient.appendChild(stopColour1);
        linearGradient.appendChild(stopColour2);

        if (x || y || x2 || y2) {
            const gradientDirection = Math.atan2(y, x) - Math.atan2(y2, x2);
            linearGradient.setAttribute('gradientTransform', `rotate(${Math.floor((gradientDirection * 180) / Math.PI + 180)})`);
        }
        return id;
    }

    color = d3.scaleOrdinal(this.data.map((d) => d.category), d3.schemeCategory10);

    initalise (el: HTMLElement, tooltipEl: HTMLElement, data: Array<ScatterChartData>, xAxisTitle: string, yAxisTitle: string) {
        this.data = data;
        this.xAxis = (a: d3.Selection<SVGElement, ScatterChartData, null, undefined>) => a
            .attr('transform', `translate(0,${this.height - this.margin.bottom})`)
            .call(d3.axisBottom(this.x).ticks(this.width / 90).tickSize(0).tickPadding(20) as never)
            .attr('font-size', 18)
            .attr('color', '#999')
            .call((g) => {
                g.select('.domain').remove();
            });

        this.yAxis = (a: d3.Selection<SVGElement, ScatterChartData, null, undefined>) => a
            .attr('transform', `translate(${this.margin.left},0)`)
            .call(d3.axisLeft(this.y).tickSize(0).tickPadding(20) as never)
            .attr('font-size', 18)
            .attr('color', '#999')
            .call((g) => g.select('.domain').remove());

        const grid = (a: d3.Selection<SVGElement, ScatterChartData, null, undefined>) => a
            .attr('stroke', '#999')
            .attr('stroke-opacity', 0.1)
            .call((g) => g.append('g')
                .selectAll('line')
                .attr('class', 'xLine')
                .datum(this.x.ticks()[0])
                .join('line')
                .attr('x1', (d) => 0.5 + (this.x(d) || 0))
                .attr('x2', (d) => 0.5 + (this.x(d) || 0))
                .attr('y1', this.margin.top)
                .attr('y2', this.height - this.margin.bottom))
            .call((g) => g.append('g')
                .selectAll('line')
                .attr('class', 'yLine')
                .data(this.y.ticks())
                .join('line')
                .attr('y1', (d) => 0.5 + (this.y(d) || 0))
                .attr('y2', (d) => 0.5 + (this.y(d) || 0))
                .attr('x1', this.margin.left)
                .attr('x2', this.width - this.margin.right));

        this.svg = d3.select(el)
            .attr('viewBox', `${-this.margin.left} 0 ${this.width + this.margin.left} ${this.height}`);

        this.defs = this.svg.append('defs');

        const xAxis = this.svg.append('g')
            .attr('class', 'xAxis')
            .call(this.xAxis as never);

        xAxis
            .append('text')
            .attr('class', 'xAxisTitle')
            .attr('x', this.width / 2)
            .attr('y', this.margin.bottom - 3)
            .attr('fill', '#000')
            .attr('text-anchor', 'middle')
            .text(xAxisTitle);

        const yAxis = this.svg.append('g')
            .attr('class', 'yAxis')
            .call(this.yAxis as never);

        yAxis.append('text')
            .attr('class', 'yAxisTitle')
            .attr('x', -this.height / 2)
            .attr('y', -this.margin.left * 1.5)
            .attr('fill', '#000')
            .attr('text-anchor', 'middle')
            .text(yAxisTitle)
            .attr('transform', 'rotate(270)')
            .attr('color', '#000');

        this.svg.append('g')
            .call(grid as never);

        const tooltip = d3.select(tooltipEl);

        this.svg.append('svg:defs').append('svg:marker')
            .attr('id', 'arrow')
            .attr('viewBox', '0 0 10 10')
            .attr('refX', 0)// so that it comes towards the center.
            .attr('refY', 3)// so that it comes towards the center.
            .attr('markerWidth', 7)
            .attr('markerHeight', 7)
            .attr('orient', 'auto')
            .append('path')
            .attr('d', 'M0,0L0,6L9,3 z');

        this.directionGroupX = this.svg.append('g');
        this.directionGroupX
            .append('text')
            .text('Less deprived area')
            .attr('x', this.width - 210)
            .attr('y', this.height - 3);

        this.directionGroupX
            .append('path')// append path
            .attr('class', 'link')
            .style('stroke', '#333')
            .attr('marker-end', () => 'url(#arrow)')
            .style('stroke-width', 2)
            .attr('d', () => `M${this.width - 60},${this.height - 6}, ${this.width - 30},${this.height - 6}`);

        this.directionGroupY = yAxis.append('g');
        this.directionGroupY
            .append('text')
            .attr('transform', 'rotate(270)')
            .attr('x', -65)
            .attr('y', -this.margin.left * 1.5 + 4)
            .attr('fill', '#000')
            .attr('text-anchor', 'end')
            .text('Healthier population');

        this.directionGroupY
            .append('path')// append path
            .attr('class', 'link')
            .style('stroke', '#333')
            .attr('marker-end', () => 'url(#arrow)')
            .style('stroke-width', 2)
            .attr('d', () => `M${-this.margin.left * 1.5},${60}, ${-this.margin.left * 1.5},${40}`);

        this.directionGroupY.attr('opacity', 0);

        this.circles = this.svg.append('g');

        this.circles.selectAll('circle')
            .data(data)
            .join('circle')
            .attr('r', (d) => d.size || 7)
            .attr('style', 'cursor: pointer')
            .attr('transform', (d) => `translate(${this.x(d.x)},${this.y(d.y)})`)
            .on('mouseenter', (e: MouseEvent, p: ScatterChartData) => {
                d3.select(e.target as SVGElement).transition().duration(200).attr('r', (d) => ((d as ScatterChartData).size || 7) + 3);

                tooltip.transition()
                    .duration(200)
                    .style('opacity', 0.9);
                const areaName = this.waitTimeData.getAreaNameFromAreaCode(p.name);
                tooltip.html(`<b>${areaName}</b><div class="stats">X: ${(Math.round(p.x * 10) / 10).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}<br/>Y: ${(Math.round(p.y * 10) / 10).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</div>`)
                    .style('left', `${e.pageX}px`)
                    .style('top', `${e.pageY - 28}px`);
            })
            .on('mouseleave', (e: MouseEvent) => {
                d3.select(e.target as SVGElement).transition().duration(200).attr('r', (d) => (d as ScatterChartData).size || 7);

                tooltip.transition()
                    .duration(200)
                    .style('opacity', 0);
                // this.healthData.selectHoveredArea(null);
            })
            .attr('fill', (d) => `url(#${this.createGradient(this.RGBToHSL(this.color(d.category)), 0, 1000, 0, 1000)})`);
    }
}
