







































import NewsApi from '@/api/newsApi';
import { Component, Vue } from 'vue-property-decorator';
import NewsItem from '@/models/newsItem';
import dayjs from 'dayjs';

@Component
export default class Home extends Vue {
    news: Array<NewsItem> = [];

    async mounted () {
        this.news = (await (NewsApi.getNews())).data.ListItems;
    }

    formatDate (date: string) {
        return dayjs(date).format('DD MMM YYYY');
    }
}
