



























import {
    Component, Vue, Prop,
} from 'vue-property-decorator';
import WaitTimeData from '@/services/waitTimeData';
import AreaStatsTable from '@/components/chart/area-stats-table.vue';
import DataType from '@/models/dataType';

@Component({ components: { AreaStatsTable } })
export default class InlineRankingsTable extends Vue {
    waitTimeData: WaitTimeData | null = null;

    @Prop()
    shorter!: boolean;

    @Prop()
    title!: boolean;

    @Prop()
    overrideDate!: string;

    async mounted () {
        this.waitTimeData = await WaitTimeData.get();
    }

    get loaded () {
        return this.waitTimeData?.loaded && !this.waitTimeData.loading;
    }

    get selectedTreatment () {
        return this.waitTimeData!.selectedTreament;
    }

    get selectedDate () {
        return this.overrideDate || this.waitTimeData!.selectedDate || '';
    }

    get selectedMetric () {
        return this.waitTimeData!.selectedMetric;
    }

    get selectedAreaName () {
        return this.selectedArea ? this.waitTimeData?.getAreaNameFromCcgCode(this.selectedArea) : this.selectedRegion;
    }

    get selectedArea () {
        return this.waitTimeData!.selectedArea!;
    }

    get selectedRegion () {
        return this.waitTimeData!.selectedRegion;
    }

    get areaData () {
        return this.waitTimeData!.localAuthorities[this.selectedArea];
    }

    rankingsTable (ccgCodes: Array<string>) {
        const areas = [];
        if (!this.selectedArea) {
            return ccgCodes.map(((a, i) => ({ areaCode: a, ranking: i + 1 })));
        }
        const r = Math.min(Math.max(this.ranking - 5, 0), this.areaCount - 9);
        let count = 10;
        if (this.ranking > 5) {
            count -= 1;
            areas.push({
                areaCode: ccgCodes[0], ranking: 1, first: true,
            });
        }
        for (let i = r; i < r + count; i += 1) {
            areas.push({ areaCode: ccgCodes[i], ranking: i + 1 });
        }
        if (areas[areas.length - 1].areaCode !== ccgCodes[ccgCodes.length - 1]) {
            areas.splice(areas.length - 1, 1);
            areas.push({
                areaCode: ccgCodes[ccgCodes.length - 1],
                ranking: ccgCodes.length,
                last: true,
            });
        }
        return areas;
    }

    get max () {
        const areas = this.waitTimeData!.getAllAreaDataForDateGroupedByArea(this.selectedDate || '', this.selectedMetric, this.selectedTreatment, DataType.Incomplete);
        return Math.max(...Object.values(areas).filter((a) => a !== null) as Array<number>);
    }

    get min () {
        const areas = this.waitTimeData!.getAllAreaDataForDateGroupedByArea(this.selectedDate || '', this.selectedMetric, this.selectedTreatment, DataType.Incomplete);
        return Math.min(...Object.values(areas).filter((a) => a !== null) as Array<number>);
    }

    get isHigherBetter () {
        return this.waitTimeData?.metrics.find((a) => a.value === this.waitTimeData?.selectedMetric)?.higherBetter;
    }

    get areaCount () {
        if (!this.selectedArea) return this.waitTimeData?.regionNames.length || 0;
        return this.waitTimeData?.areaCodes.length || 0;
    }

    get ranking (): number {
        if (!this.selectedArea) return this.rankings.indexOf(this.waitTimeData!.getRegionCodeFromRegionName(this.selectedRegion!));
        return this.rankings.indexOf(this.selectedArea);
    }

    get rankings () {
        if (!this.selectedRegion) {
            return [...this.highestWaitTimeAreas, ...this.lowestWaitTimeAreas];
        }
        if (!this.selectedArea) {
            const areas = this.waitTimeData!.getAllAreaDataForDateGroupedByCcg(this.selectedDate || '', this.selectedMetric, this.selectedTreatment, DataType.Incomplete, true);
            const regionAreas = this.waitTimeData!.getAllAreasInRegionForDate(this.selectedRegion);
            const sorted = Object.keys(areas).filter((a) => regionAreas.indexOf(a) > -1).sort((a, b) => {
                if (areas[a] === areas[b]) {
                    return -this.comapreNames(a, b);
                }
                return (areas[a] ?? -1) < (areas[b] ?? -1) ? 1 : -1;
            });
            return this.isHigherBetter ? sorted : sorted.reverse();
        }
        const areas = this.waitTimeData!.getAllAreaDataForDateGroupedByCcg(this.selectedDate || '', this.selectedMetric, this.selectedTreatment, DataType.Incomplete, true);
        const sorted = Object.keys(areas).filter((a) => areas[a] !== null).sort((a, b) => {
            if (areas[a] === areas[b]) {
                return -this.comapreNames(a, b);
            }
            return areas[a]! < areas[b]! ? 1 : -1;
        });
        return this.isHigherBetter ? sorted : sorted.reverse();
    }

    get highestWaitTimeAreas () {
        if (!this.waitTimeData) return [];
        const areas = this.waitTimeData.getAllAreaDataForDateGroupedByCcg(this.selectedDate, this.selectedMetric, this.selectedTreatment, DataType.Incomplete, true);
        const sorted = Object.keys(areas).filter((a) => areas[a] !== null).sort((a, b) => {
            if (areas[a] === areas[b]) {
                return this.comapreNames(a, b);
            }
            return areas[a]! < areas[b]! ? 1 : -1;
        });
        return sorted.filter((a, i) => i < (this.shorter ? 5 : 10));
    }

    get lowestWaitTimeAreas () {
        if (!this.waitTimeData) return [];
        const areas = this.waitTimeData.getAllAreaDataForDateGroupedByCcg(this.selectedDate, this.selectedMetric, this.selectedTreatment, DataType.Incomplete, true);
        const sorted = Object.keys(areas).filter((a) => areas[a] !== null).sort((a, b) => {
            if (areas[a] === areas[b]) {
                return this.comapreNames(a, b);
            }
            return areas[a]! > areas[b]! ? 1 : -1;
        });
        return sorted.filter((a, i) => i < (this.shorter ? 5 : 10));
    }

    comapreNames (ccgCodeA: string, ccgCodeB: string) {
        const aName = this.waitTimeData!.getAreaNameFromCcgCode(ccgCodeA);
        const bName = this.waitTimeData!.getAreaNameFromCcgCode(ccgCodeB);
        return aName! > bName! ? 1 : -1;
    }
}
