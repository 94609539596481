<template>
<div class="input-field" v-click-outside="clickedOutside" :class="{ 'has-info': showMobileInfo }">
    <label class="required" :for="id">{{ name }}</label>
    <div class="input-wrapper">
        <input class="validate" :readonly="true" :class="{ dirty: dirty, focused: focused, valid: value, 'has-icon': selectedValue && selectedValue.icon }"
            required="required" type="text" :id="id" :value="selectedValue ? selectedValue[nameKey] : selectedValue" v-focus="focused"
            @click="caretMoved" @keydown.down.prevent="downPressed" @keydown.tab="tabPressed" @keydown.right.prevent="rightPressed"
            @keydown.up.prevent="upPressed" @keyup.enter="enterPressed" @keydown.left.prevent="leftPressed" ref="input" />
        <div class="icon" v-if="selectedValue && selectedValue.icon" :style="{ background: selectedValue.color }" >
            <font-awesome-icon :icon="['fad', selectedValue.icon]" />
            <font-awesome-icon class="secondary-icon" v-if="selectedValue.secondaryIcon" :icon="['fad', selectedValue.secondaryIcon]" />
        </div>
    </div>
    <font-awesome-icon style="pointer-events: none" class="arrow" :icon="['fad', 'caret-down']" :class="{ flip: focused }"/>
    <div class="message" />
    <div class="dropdown-wrapper" v-show="focused" ref="dropdown">
        <div class="dropdown">
            <dropdown-item :class="{selected: item === selected}" :ref="item ? item[valueKey] : i" v-for="(item, i) in options" :key="item ? item[valueKey] : i"
                :item="item" :value="value" :nameKey="nameKey" :valueKey="valueKey" @hovered-item="hoveredItem" :hovered="hovered"
                @item-selected="itemSelected" :selected="selected" :parent="item" :grandparent="null">
            </dropdown-item>
        </div>
    </div>
    <font-awesome-icon class="info" v-if="showMobileInfo" :icon="['fad', 'question-circle']" @click="infoClicked"/>
</div>
</template>

<script>
import { generateGuid } from '../directives/click-outside';
import dropdownItem from './dropdown-list-item.vue';

export default {
    components: {
        dropdownItem,
    },
    props: {
        value: [String, Number],
        name: String,
        options: Array,
        valueKey: String,
        nameKey: String,
        showMobileInfo: Boolean,
    },
    name: 'dropdown',
    data () {
        return {
            id: '',
            dirty: false,
            selectedValue: '',
            focused: false,
            selected: null,
            hovered: null,
        };
    },
    created () {
        this.id = generateGuid();
    },
    watch: {
        focused () {
            if (this.focused) {
                document.addEventListener('scroll', this.scrollEvent, true);
            } else {
                document.removeEventListener('scroll', this.scrollEvent);
            }
        },
        selectedValue () {
            this.dirty = true;
            this.hovered = null;
            this.$emit('input', this.selectedValue ? this.selectedValue[this.valueKey] : null);
        },
        value: {
            handler () {
                const item = this.findItem(this.options, this.value);
                this.selectedValue = item;
                this.selected = item;
            },
            immediate: true,
        },
        options: {
            handler () {
                if (!this.findItem(this.options, this.value)) this.itemSelected(this.options[0]);
            },
        },
        hovered () {
            this.$emit('hoveredItem', this.hovered);
        },
    },
    methods: {
        infoClicked () {
            this.hovered = this.selected;
        },
        scrollEvent (e) {
            if (e.target.className === 'scroll' && this.focused) {
                this.focused = false;
                (this.$refs.input).blur();
                document.removeEventListener('scroll', this.scrollEvent);
            }
        },
        findItem (items, value) {
            if (!items) return null;
            for (let i = 0; i < items.length; i += 1) {
                if (items[i][this.valueKey] === value) return items[i];
                const item = this.findItem(items[i].children, value);
                if (item) return item;
            }
            return null;
        },
        findItemParent (items, item, parent) {
            if (!items) return null;
            if (items.find((a) => a === item)) return parent;
            for (let i = 0; i < items.length; i += 1) {
                const found = this.findItemParent(items[i].children, item, items[i]);
                if (found) return found;
            }
            return null;
        },
        hoveredItem (item) {
            this.hovered = item;
        },
        clickedOutside () {
            this.hovered = null;
            this.focused = false;
            this.$emit('hoveredItem', null);
        },
        downPressed (e) {
            const options = this.hovered ? this.hovered.children : this.options;

            if (!this.selected) {
                this.selected = options[0];
            } else {
                const found = options.find((item) => item === this.selected);
                const index = options.indexOf(found);
                if (index < options.length - 1) {
                    this.selected = options[index + 1];
                }
            }
            this.scrollToItem();
            e.preventDefault();
            e.stopPropagation();
        },
        scrollToItem () {
            if (this.selected) {
                this.$emit('hoveredItem', this.selected);
                const el = this.$refs[this.selected[this.valueKey]];
                if (el) {
                    const topPos = el[0].offsetTop;
                    this.$refs.dropdown.scrollTop = topPos - 24;
                }
            }
        },
        upPressed (e) {
            const options = this.hovered ? this.hovered.children : this.options;
            if (!this.selected) {
                this.selected = options[0];
            } else {
                const found = options.find((item) => item === this.selected);
                const index = options.indexOf(found);
                if (index > 0) {
                    this.selected = options[index - 1];
                }
            }
            this.scrollToItem();
            e.preventDefault();
            e.stopPropagation();
        },
        rightPressed (e) {
            if (this.selected && this.selected.children?.length) {
                this.hovered = this.selected;
                this.selected = this.hovered.children[0];
            }
            e.preventDefault();
            e.stopPropagation();
        },
        leftPressed (e) {
            if (this.selected) {
                const parent = this.findItemParent(this.options, this.selected);
                if (!parent) return;
                this.selected = parent;
                const parentParent = this.findItemParent(this.options, parent);
                this.hovered = parentParent;
            }
            e.preventDefault();
            e.stopPropagation();
        },
        enterPressed () {
            if (this.selected) {
                this.selectedValue = this.selected;
            }
            this.focused = false;
            this.$refs.input.blur();
        },
        tabPressed () {
            this.focused = false;
        },
        itemSelected (item) {
            this.selected = item;
            this.selectedValue = item;
            this.focused = false;
            this.hovered = null;
        },
        caretMoved () {
            this.dirty = true;
            this.focused = !this.focused;
            // this.showList();
        },
    },
};
</script>

<style lang="scss" scoped>
    @import '@/scss/colours.scss';
    .info {
        color: $lcpBlue;
        font-size: 1.5rem;
        position: absolute;
        right: 0;
        display: none;
        @media (max-width: 900px) {
            display: block;
        }
    }
    label {
        white-space: nowrap;
        padding-right: 0.5rem;
    }
    .input-field {
        display: flex;
        align-items: center;
        position: relative;
        padding-right: 1rem;
        &.has-info {
            @media (max-width: 900px) {
                padding-right: 3.5rem;
                .arrow {
                    right: 2.5rem;
                }
            }
        }
    }
    input {
        width: 100%;
        @media (max-width: 900px) {
            font-size: 16px;
        }
        padding: 0.25rem;
        &:hover {
            cursor: pointer;
        }
        &.has-icon {
            padding-left: 3.25rem;
            width: calc(100% - 4rem);
        }
    }

    .icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background: $lcpBlue;
        padding: 0.25rem;
        border-radius: 100%;
        width: 30px;
        left: 0.5rem;
        height: 30px;
        text-align: center;
        margin-right: 1rem;
        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 1.2rem;
            color: #fff;
        }
    }

    svg.secondary-icon {
        position: absolute;
        font-size: 0.8rem;;
        color: darken($lcpBlue, 10);

    }

    input {
        padding: 0.75rem;
        padding-right: 2rem;
        border: none;
        width: calc(100% - 1.5rem);
        display: inline-block;
        background: #fff;
        border: 1px solid #ccc;
        box-shadow: none;
    }
    .arrow {
        position: absolute;
        right: 0rem;
        font-size: 1.7rem;
        color: #666;
        &.flip {
            transform: scaleY(-1);
        }
    }

    .input-wrapper {
        width: 100%;
        position: relative;
    }

    .dropdown {
        position: relative;
        height: 100%;
        overflow: auto;
        max-height: 16.5rem;
        padding: 0.25rem;
    }

    .dropdown-wrapper {
        position: absolute;
        background: white;
        width: 100%;
        top: 2.75rem;
        z-index: 99;
        box-shadow: rgba(0, 0, 0, 0.4) 0 4px 1rem;
    }

</style>
