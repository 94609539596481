/* eslint-disable @typescript-eslint/no-explicit-any */
import * as d3 from 'd3';

export default class Postcodes {
    private static postcodes: Postcodes;

    postcodeMap: Array<{ postcode: string; ccgCode: string; ccgName: string }> = [];

    static async get () {
        if (!this.postcodes) {
            this.postcodes = new Postcodes();
            await this.postcodes.initialise();
        }
        return this.postcodes;
    }

    async initialise () {
        await d3.csv('/CCG_PostalArea_Mapping.csv').then((response) => {
            this.postcodeMap = response as unknown as Array<{ postcode: string; ccgCode: string; ccgName: string }>;
        });
    }

    getAreaCodeFromPostcode (postcode: string) {
        return this.removeDuplicates(this.postcodeMap
            .filter((a) => a.postcode.toLowerCase().indexOf(postcode.split(' ')[0].toLowerCase()) > -1 || a.ccgName.replace('NHS ', '').toLowerCase().indexOf(postcode.replace('NHS ', '').toLowerCase()) > -1)
            .sort((a, b) => (a.postcode.indexOf(postcode) > b.postcode.indexOf(postcode) ? 1 : -1)), 'ccgCode');
    }

    removeDuplicates (arr: Array<any>, prop: string) {
        return arr.filter((obj, pos, a) => a.map((mapObj: any) => mapObj[prop]).indexOf(obj[prop]) === pos);
    }

    getPostCodeFromAreaCode (ccgCode: string) {
        return this.postcodeMap.filter((a) => a.ccgCode.toLowerCase() === ccgCode.toLowerCase());
    }
}
