































import { Chart, TooltipFormatterContextObject } from 'highcharts';

import {
    Component, Vue, Prop, Watch,
} from 'vue-property-decorator';
import WaitTimeData from '@/services/waitTimeData';
import TreatmentType from '@/models/treatmentType';
import CcgCsvData from '@/models/ccgCsvData';
import dropdown from '@/components/dropdown.vue';

@Component({ components: { dropdown } })
export default class TreatmentWaitingTimes extends Vue {
    @Prop()
    metric!: keyof CcgCsvData;

    @Prop()
    title!: keyof CcgCsvData;

    @Prop()
    selectedTreatment!: TreatmentType;

    @Prop()
    selectedArea!: string;

    @Prop()
    selectedRegion!: string;

    waitTimeData: WaitTimeData | null = null;

    hasClicked = false;

    unmetNeeds: any = {};

    async mounted () {
        this.waitTimeData = await WaitTimeData.get();
        this.$nextTick(() => {
            this.selectYear();
            this.chart.reflow();
        });
    }

    calculateUnmetNeeds () {
        if (!this.waitTimeData || !this.waitTimeData!.loaded || this.metric !== 'totalNumberOfIncompletePathways') return;
        const metricDisplayName = this.waitTimeData?.metrics.find((a) => a.value === this.metric)?.name ?? this.metric;

        this.unmetNeeds = {
            name: `Unmet needs ${metricDisplayName} in ${this.selectedRegion}`,
            data: this.categories.map((date) => (this.waitTimeData?.getNationalTotalForDate(date, 'unmetNeeds', this.selectedTreatment) ?? 0) * ((this.waitTimeData?.selectedSplit || 1))),
            type: 'areaspline',
            opacity: this.selectedRegion ? 0.2 : 1,
            lineWidth: 3,
            zoneAxis: 'x',
            zones: [{
                value: 43,
            }, {
                dashStyle: 'dash',
                className: 'future',
            }],
            fill: '#919EA8',
            color: '#919EA8',
        };
    }

    get chartData () {
        if (!this.waitTimeData || !this.waitTimeData!.loaded) return [];

        const metricDisplayName = this.waitTimeData?.metrics.find((a) => a.value === this.metric)?.name ?? this.metric;

        let data = [
            {
                name: `${metricDisplayName} in England`,
                data: this.categories.map((date) => (this.waitTimeData?.getNationalTotalForDate(date, this.metric, this.selectedTreatment) ?? 0) * ((this.waitTimeData?.selectedSplit || 1))),
                type: 'areaspline',
                lineWidth: 3,
                opacity: this.selectedRegion ? 0.2 : 1,
                zoneAxis: 'x',
                zones: [{
                    value: 43,
                }, {
                    dashStyle: 'dash',
                    className: 'future',
                }],
                fill: '#005eb8',
                color: '#005eb8',
            }];

        if (this.selectedArea) {
            if (this.isTotalMetric) data = [];
            data.push({
                name: `${metricDisplayName} in ${this.waitTimeData.getAreaNameFromCcgCode(this.selectedArea)}`,
                data: this.waitTimeData?.getTotalsForAllDatesForArea(this.selectedArea, this.metric, this.selectedTreatment).map((a) => (a ?? 0) * (this.waitTimeData!.selectedSplit)),
                type: 'line',
                opacity: 1,
                lineWidth: 3,
                zoneAxis: 'x',
                zones: [{
                    value: 43,
                }, {
                    dashStyle: 'dash',
                    className: 'future',
                }],
                fill: '#005eb8',
                color: '#005eb8',
            });
        } else if (this.selectedRegion) {
            data.push({
                name: `${metricDisplayName} in ${this.selectedRegion}`,
                data: this.waitTimeData?.getTotalsForAllDatesForRegion(this.selectedRegion, this.metric, this.selectedTreatment).map((a) => (a ?? 0) * (this.waitTimeData!.selectedSplit)),
                type: 'line',
                opacity: 1,
                lineWidth: 3,
                zoneAxis: 'x',
                zones: [{
                    value: 43,
                }, {
                    dashStyle: 'dash',
                    className: 'future',
                }],
                fill: '#99C7EB',
                color: '#99C7EB',
            });
        }
        if (!this.selectedArea && this.unmetNeeds && this.metric === 'totalNumberOfIncompletePathways') {
            this.unmetNeeds.opacity = this.selectedRegion ? 0.2 : 1;
            data.splice(0, 0, this.unmetNeeds);
        }

        return data;
    }

    gotoPreviousDate () {
        this.$emit('areaChanged', this.newCcgAreas![0].value);
    }

    gotoNewCcg () {
        const newCcg = this.waitTimeData?.getNewCcgForCcg(this.selectedArea);
        this.$emit('areaChanged', newCcg);
    }

    get categories () {
        if (!this.waitTimeData) return [];
        return this.waitTimeData.dates;
    }

    get isNewArea () {
        const newCcg = this.waitTimeData?.getNewCcgForCcg(this.selectedArea);
        return newCcg !== '' && newCcg === this.selectedArea;
    }

    get isNewMetric () {
        if (!this.waitTimeData) return false;
        return this.waitTimeData?.additionalTotalMetrics.filter((x) => x.value === this.metric).length > 0;
    }

    get isNewTreatmentType () {
        switch (this.selectedTreatment) {
        case '14':
        case '15':
        case '16':
        case '17':
        case '18':
            return true;
        default:
            return false;
        }
    }

    get hasNewMapping () {
        const newCcg = this.waitTimeData?.getNewCcgForCcg(this.selectedArea);
        return newCcg !== '' && newCcg !== this.selectedArea;
    }

    get newCcgAreas () {
        return this.waitTimeData?.getGroupedAreaCodes(this.selectedArea).map((area) => ({
            name: this.waitTimeData?.getAreaNameFromAreaCode(area, false),
            value: this.waitTimeData?.getCcgCodeForAreaCode(area, false),
            area,
        }));
    }

    get isMobile () {
        return window.innerWidth < 900;
    }

    seriesToggled (data: { name: string; visible: boolean }) {
        const series = this.chart.series.find((a) => a.name === data.name);
        if (series) series.setVisible(data.visible);
    }

    get isTotalMetric () {
        return this.waitTimeData?.metrics.find((a) => a.value === this.waitTimeData?.selectedMetric)?.total;
    }

    selectYear () {
        if (this.chart && this.chart.series.length && this.chart.series[0].points.length) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            const e: PointerEventObject = { target: this.chart.series[0].points[0] };
            if (this.chart.series[0].points[0].y != null) {
                const point = this.chart.series[0].points.find((a) => a.category === this.waitTimeData?.selectedDate);
                if (point) point.onMouseOver(e);
                else this.chart.series[0].points[0].onMouseOver(e);
            }
        }
        this.chart.tooltip.hide();
    }

    get selectedDate () {
        return this.waitTimeData?.selectedDate;
    }

    @Watch('selectedDate')
    selectedDateChanged () {
        if (!this.waitTimeData || !this.selectedDate) return;
        if (this.selectedDate !== this.waitTimeData.dates[this.waitTimeData.dates.length - 1]) {
            this.hasClicked = true;
        }
        if (this.chart) this.chart.redraw(false);
    }

    get chart () {
        return (this.$refs.chart as unknown as { chart: Chart}).chart;
    }

    get loading () {
        return this.waitTimeData?.loading;
    }

    get split () {
        return this.waitTimeData?.selectedSplit ?? 1;
    }

    @Watch('split')
    @Watch('metric', { immediate: true })
    @Watch('selectedTreatment')
    @Watch('loading')
    metricChanged () {
        this.$nextTick(() => {
            this.calculateUnmetNeeds();
        });
    }

    @Watch('loading')
    @Watch('chartData')
    loadingChanged () {
        if (this.loading) return;
        this.$nextTick(() => {
            this.selectYear();
        });
    }

    get chartOptions () {
        const waitTimeData = this.waitTimeData;
        return {
            title: {
                text: '',
            },
            chart: {
                type: 'areaspline',
                backgroundColor: 'transparent',
                height: (window.innerHeight - 70 - 70 - 200 - 64) / 2,
            },
            xAxis: {
                categories: this.categories,
                labels: {
                    style: {
                        fontFamily: 'Roboto',
                    },
                },
                crosshair: {
                    enabled: true,
                    color: 'rgba(0, 163, 199, 0.15)',
                },
                plotBands: [{
                    color: 'rgba(0, 0, 0, 0.035)',
                    from: 43,
                    to: 96,
                    label: {
                        useHTML: true,
                        text: '<span>Forecast</span>',
                        align: 'center',
                        y: 20,
                        x: -50,
                        style: {
                            padding: '10px',
                            width: '350px',
                            color: '#fff',
                            fontWeight: 700,
                            opacity: 0.7,
                            whiteSpace: 'pre-wrap',
                            textAlign: 'center',
                            zIndex: '2',
                            borderRadius: '2px',
                            fontSize: '0.8rem',
                            background: '#005eb8',
                            fontFamily: 'Roboto',
                        },
                    },
                }],
            },
            yAxis: {
                title: {
                    text: '',
                    style: {
                        fontFamily: 'Roboto',
                    },
                },
                labels: {
                    style: {
                        fontFamily: 'Roboto',
                    },
                    formatter (a: { value: number }): string {
                        return `${(a.value.toLocaleString())}`;
                    },
                },
            },

            plotOptions: {
                series: {
                    marker: {
                        enabled: false,
                    },
                    point: {
                        events: {
                            mouseOver: (e: { target: { category: string }}) => {
                                waitTimeData!.selectDate(e.target.category);
                            },
                            mouseOut: (e: MouseEvent) => { e.preventDefault(); this.chart.tooltip.hide(); return false; },

                        },
                    },
                    events: {
                        mouseOut: (e: MouseEvent) => { e.preventDefault(); this.chart.tooltip.hide(); return false; },
                    },
                },
                areaspline: {
                    stacking: 'normal',
                    lineColor: '#666666',
                    lineWidth: 1,
                    marker: {
                        lineWidth: 1,
                        lineColor: '#666666',
                    },
                },
            },
            tooltip: {
                shared: true,
                formatter (): string {
                    const self = this as unknown as TooltipFormatterContextObject;

                    return self.points!.reduce((s, point) => `${s}<br/><span style="color:${point.color}">\u25CF</span>
                        ${point.series.name}: <b>${Math.round(point.y).toLocaleString()}</b>`, `<i>${self.x}</i>`);
                },
                style: {
                    fontFamily: 'Roboto',
                    fontSize: '0.9rem',
                },
            },
            legend: {
                enabled: false,
            },
            credits: {
                enabled: false,
            },
            colors: [
                '#005eb8',
                '#005EB8',
                '#666',
                '#99C7EB',
                '#61093f',
                '#b7d878',
                '#2bb673',
                '#7167ae',
                '#ea9fc6',
                '#e4205f',
                '#005c5f',
                '#61093f',
                '#002e5f',
                '#fcb769',
                '#f48c89',
                '#92b1b6',
                '#33587f',
                '#33b6d2',
                '#5ad4a7',
                '#03628b',
                '#387e23',
                '#a53c13',
                '#9a9c00',
                '#198ea0',
                '#46a050',
                '#b2693b',
                '#b2a945',
                '#4aae89'],
            series: this.chartData || [],
        };
    }
}
