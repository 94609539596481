import CcgCsvData from '@/models/ccgCsvData';
import GeographicLevel from '@/models/geographicLevel';
import TreatmentType from '@/models/treatmentType';
import axios from 'axios';

const apiCode = process.env.VUE_APP_API_CODE;
const apiUrl = process.env.VUE_APP_API_URL;

export default class WaitTimeApi {
    static async getNationalData (metric: keyof CcgCsvData) {
        return axios.get<{ [ key in TreatmentType]: { [monthPeriod: string]: number }}>(`${apiUrl}/national/metric/${metric}?code=${apiCode}`).then((response) => response.data);
    }

    static async getRegionalData (metric: keyof CcgCsvData) {
        return axios.get<{ [ region: string ]: { [ key in TreatmentType]: { [monthPeriod: string]: number }}}>(`${apiUrl}/regional/metric/${metric}?code=${apiCode}`).then((response) => response.data);
    }

    static async getCcgDataForTreatment (metric: keyof CcgCsvData, treatment: TreatmentType) {
        return axios.get<{ [ ccgCode: string]: { [monthPeriod: string]: number }}>(`${apiUrl}/ccg/metric/${metric}/treatment/${treatment}?code=${apiCode}`).then((response) => response.data);
    }

    static async getAllTreatmentTypesForCcg (metric: keyof CcgCsvData, ccgCode: string) {
        return axios.get<{ [ key in TreatmentType]: { [monthPeriod: string]: number }}>(`${apiUrl}/ccg/${ccgCode}/metric/${metric}?code=${apiCode}`).then((response) => response.data);
    }

    static async getLatestData () {
        return axios.get<{ [key in GeographicLevel]: { [ region: string ]: { [ key in TreatmentType]: { [monthPeriod: string]: CcgCsvData }}}}>(`${apiUrl}/latest?code=${apiCode}`).then((response) => response.data);
    }

    static async getHeadlineData (treatmentType: TreatmentType) {
        return axios.get<{ [key in GeographicLevel]: { [ region: string ]: { [monthPeriod: string]: CcgCsvData }}}>(`${apiUrl}/headline/${treatmentType}?code=${apiCode}`).then((response) => response.data);
    }
}
