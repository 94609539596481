
















import {
    Chart, TooltipFormatterContextObject,
} from 'highcharts';

import {
    Component, Vue, Prop, Watch,
} from 'vue-property-decorator';
import WaitTimeData from '@/services/waitTimeData';
import TreatmentType from '@/models/treatmentType';
import TreatmentTypeUtil from '@/models/treatmentTypeUtil';
import CcgCsvData from '@/models/ccgCsvData';

@Component
export default class TreatmentWaitingTimes extends Vue {
    @Prop()
    metric!: keyof CcgCsvData;

    @Prop()
    title!: keyof CcgCsvData;

    @Prop()
    selectedDate!: string;

    @Prop()
    selectedArea!: string;

    @Prop()
    selectedRegion!: string;

    waitTimeData: WaitTimeData | null = null;

    hasClicked = false;

    previousTreatmentType: TreatmentType = TreatmentType['All specialties (total)'];

    hideTotal = true;

    async mounted () {
        this.waitTimeData = await WaitTimeData.get();
        this.$nextTick(() => {
            if (this.chart) this.chart!.redraw();
        });
    }

    get loading () {
        return this.waitTimeData?.loading;
    }

    get isShowingDrilldown () {
        return (TreatmentTypeUtil.isDrilldownType(this.selectedTreatment!) || this.selectedTreatment === TreatmentType['Other (total)']);
    }

    get chartData () {
        if (!this.waitTimeData || !this.waitTimeData!.loaded) return [];

        if (this.isShowingDrilldown) {
            return this.otherDrilldown;
        }

        const output: { name: string|undefined; y: number|null|undefined }[] = [];

        Object.values(TreatmentType).forEach((treatmentType) => {
            if (treatmentType === '22' && this.hideTotal) return;
            if (treatmentType !== '13' && TreatmentTypeUtil.isDrilldownType(treatmentType)) return;
            output.push({ name: this.waitTimeData?.getTreatmentNameFromKey(treatmentType), y: (this.getDataForTreatmentType(treatmentType) ?? 0) * this.waitTimeData!.selectedSplit });
        });

        return output;
    }

    get otherDrilldown () {
        if (!this.waitTimeData || !this.waitTimeData!.loaded) return [];

        const output: { name: string|undefined; y: number|null|undefined}[] = [];

        Object.values(TreatmentType).forEach((treatmentType) => {
            if (!TreatmentTypeUtil.isDrilldownType(treatmentType)) return;
            output.push({ name: this.waitTimeData?.getTreatmentNameFromKey(treatmentType), y: this.getDataForTreatmentType(treatmentType) });
        });

        return output;
    }

    getDataForTreatmentType (treatmentType: TreatmentType) {
        if (this.selectedArea) return this.waitTimeData?.getAllAreaDataForDateGroupedByCcg(this.selectedDate, this.metric, treatmentType)[this.selectedArea];
        if (this.selectedRegion) return this.waitTimeData?.getAllRegionDataForDateGroupedByRegion(this.selectedDate, this.metric, treatmentType)[this.waitTimeData.getRegionCodeFromRegionName(this.selectedRegion)];
        return this.waitTimeData?.getNationalTotalForDate(this.selectedDate, this.metric, treatmentType);
    }

    get selectedTreatment () {
        return this.waitTimeData?.selectedTreament;
    }

    get highestY () {
        if (!this.waitTimeData || !this.waitTimeData!.loaded) return undefined;

        return this.waitTimeData!.highestForSelected(this.categories.map((x) => TreatmentType[x as keyof typeof TreatmentType]));
    }

    get showNoDataForDrilldown () {
        return this.isShowingDrilldown && !this.waitTimeData?.dateAfterNewDataDate(this.selectedDate);
    }

    @Watch('loaded')
    hasLoaded () {
        this.$nextTick(() => {
            if (this.chart?.series[0]) {
                const index = this.chart!.series[0].points.map((x) => x.name).indexOf('All specialties (total)');
                if (index !== -1) this.chart!.series[0].points[index].select(true, true);
            }
        });
        this.updateMaxY();
    }

    updateMaxY () {
        this.$nextTick(() => {
            this.chart!.yAxis[0].update({ max: this.highestY });
        });
    }

    @Watch('selectedTreatment')
    selectedTreatmentChanged (previous: TreatmentType) {
        if (this.selectedTreatment !== TreatmentType['All specialties (total)']) {
            this.hasClicked = true;
        }
        if (!this.isShowingDrilldown) {
            this.previousTreatmentType = previous;
        }
        if (this.chart) this.chart.redraw(false);

        this.$nextTick(() => {
            if (this.chart?.series[0]) {
                if (this.hideTotal && this.selectedTreatment === TreatmentType['All specialties (total)']) {
                    this.chart!.series[0].points.forEach((point) => point.select(false));
                } else {
                    const index = this.chart!.series[0].points.map((x) => x.name).indexOf(TreatmentTypeUtil.getKeyFromValue(this.selectedTreatment!));
                    if (index !== -1) this.chart!.series[0].points[index].select(true, true);
                }
            }
        });
        this.updateMaxY();
    }

    returnFromDrilldown () {
        this.waitTimeData!.selectTreatment(this.previousTreatmentType);
    }

    selectTotal () {
        this.waitTimeData!.selectTreatment(TreatmentType['All specialties (total)']);
    }

    get loaded () {
        return this.waitTimeData?.loaded;
    }

    get categories () {
        if (this.isShowingDrilldown) {
            return TreatmentTypeUtil.getAllDrilldownTypes();
        }
        return Object.keys(TreatmentType).filter((x) => (x === 'Other (total)' || !TreatmentTypeUtil.isDrilldownType(TreatmentType[x as keyof typeof TreatmentType])) && (!this.hideTotal || (this.hideTotal && x !== 'All specialties (total)')));
    }

    seriesToggled (data: { name: string; visible: boolean }) {
        const series = this.chart?.series.find((a) => a.name === data.name);
        if (series) series.setVisible(data.visible);
    }

    toggleTotal () {
        this.hideTotal = !this.hideTotal;
        this.$nextTick(() => {
            if (this.chart?.series[0]) {
                const index = this.chart!.series[0].points.map((x) => x.name).indexOf(TreatmentTypeUtil.getKeyFromValue(this.selectedTreatment!));
                if (index !== -1) this.chart!.series[0].points[index].select(true, true);
            }
        });
    }

    get showBackToAll () {
        return this.hideTotal && this.selectedTreatment !== TreatmentType['All specialties (total)'];
    }

    get chart () {
        if (!this.$refs.chart) return null;
        return (this.$refs.chart as unknown as { chart: Chart}).chart;
    }

    get chartOptions () {
        const waitTimeData = this.waitTimeData;
        const nextTick = this.$nextTick;
        return {
            title: {
                text: '',
            },
            chart: {
                type: 'column',
                backgroundColor: 'transparent',
                height: (window.innerHeight - 70 - 70 - 48 - 80) / 2,
                alignTicks: false,
                endOnTick: false,
            },
            xAxis: {
                labels: {
                    style: {
                        fontFamily: 'Roboto',
                        whiteSpace: 'nowrap',
                    },
                    overflow: 'none',
                    formatter (): string {
                        const self = this as unknown as Highcharts.AxisLabelsFormatterContextObject;
                        const label = self.axis.defaultLabelFormatter.call(self);
                        if (waitTimeData?.getTreatmentKeyFromName(label) === waitTimeData?.selectedTreament) return `<b>${label}</b>`;
                        nextTick(() => {
                            const els = document.getElementsByClassName('highcharts-axis-labels highcharts-xaxis-labels');
                            if (els.length) {
                                const el = (els[0]as HTMLElement);
                                for (let i = 0; i < el.children.length; i += 1) {
                                    if (el.children[i].innerHTML.replace('&amp;', '&') === label) {
                                        (el.children[i] as HTMLElement).style.cursor = 'pointer';
                                        (el.children[i] as HTMLElement).onclick = () => {
                                            self.chart.series[0].points[self.pos].select(true, false);
                                        };
                                    }
                                }
                            }
                        });
                        return label;
                    },
                },
                type: 'category',
                categories: this.categories,
                crosshair: {
                    enabled: true,
                    color: 'rgba(0, 0, 0, 0)',

                },
                clickOnCrosshair (e: unknown, p: Highcharts.Point) {
                    p.select(true, false);
                },
            },
            yAxis: {
                max: this.highestY,
                title: {
                    text: '',
                    style: {
                        fontFamily: 'Roboto',
                    },
                },
                labels: {
                    style: {
                        fontFamily: 'Roboto',
                    },
                    formatter (a: { value: number }): string {
                        return `${(a.value.toLocaleString())}`;
                    },
                },
            },
            plotOptions: {
                series: {
                    states: {
                        select: {
                            color: '#ED8B00',
                        },
                    },
                    allowPointSelect: true,
                    marker: {
                        enabled: false,
                    },
                    point: {
                        events: {
                            select: (e: { target: { name: string }}) => {
                                waitTimeData!.selectTreatment(waitTimeData!.getTreatmentKeyFromName(e.target.name));
                            },
                            unselect: (e: { target: { name: string }}) => {
                                const treatmentKey = waitTimeData!.getTreatmentKeyFromName(e.target.name);
                                if (waitTimeData!.selectedTreament !== treatmentKey) return;
                                const point = this.chart!.series[0].points.find((a) => a.category === e.target.name);
                                if (point) {
                                    this.$nextTick(() => {
                                        point.select(true);
                                    });
                                }
                            },
                            // mouseOut: (e: MouseEvent) => { e.preventDefault(); this.chart.tooltip.hide(); return false; },

                        },
                    },
                    events: {
                        mouseOut: (e: MouseEvent) => { e.preventDefault(); this.chart!.tooltip.hide(); return false; },
                    },
                },
            },
            tooltip: {
                shared: true,
                formatter (): string {
                    const self = this as unknown as TooltipFormatterContextObject;

                    return self.points!.reduce((s, point) => `<span style="color:${point.color}">\u25CF</span>
                        ${s} <b> ${Math.round(point.y).toLocaleString()}</b>`, `<i>${self.points![0].key}</i>`);
                },
                style: {
                    fontFamily: 'Roboto',
                    fontSize: '0.9rem',
                },
            },
            legend: {
                enabled: false,
            },
            credits: {
                enabled: false,
            },
            colors: ['#005eb8',
                '#005EB8'],
            series: {
                name: 'Specialities',
                data: this.chartData || [],
            },
        };
    }
}
