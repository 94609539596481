export default class MidpointDisplacement {
    segmentCount: number;

    points: Array<number> = [];

    constructor (segmentCount: number) {
        this.segmentCount = segmentCount;
        this.points = [];
        for (let i = 0; i <= segmentCount; i += 1) {
            this.points[i] = 0;
        }
    }

    generateUsingMidPoint (minNumber: number, maxNumber: number, sharpness: number) {
        let direction = 1;
        this.points[0] = Math.max(this.points[0] + (direction * Math.random() * (maxNumber / 60)), minNumber);
        for (let i = 1; i < this.segmentCount; i += 1) {
            this.points[i] = Math.max(this.points[i - 1] + (direction * Math.random() * (maxNumber / 60)), minNumber);
            if (i > 60) {
                direction = Math.random() < 0.2 ? 1 : -1;
            } else if (i > 10) {
                direction = Math.random() < 0.9 ? 1 : -1;
            } else {
                direction = Math.random() < 0.5 ? 1 : -1;
            }
            if (this.points[i] <= minNumber) direction = 1;
        }
        // this.midPoint(0, this.segmentCount, minNumber, maxNumber, sharpness);
    }

    midPoint (start: number, end: number, minNumber: number, maxNumber: number, sharpness: number) {
        const middle = Math.round((start + end) * 0.5);
        if ((end - start <= 1) || middle === start || middle === end) {
            return;
        }

        const newAltitude = 0.5 * (this.points[end] + this.points[start]) + maxNumber * (1 - 2 * Math.random());
        this.points[middle] = Math.abs(newAltitude + minNumber);

        this.midPoint(start, middle, minNumber, maxNumber * sharpness, sharpness);
        this.midPoint(middle, end, minNumber, maxNumber * sharpness, sharpness);
    }
}
