enum TreatmentType {
    'Cardiology'= '0',
    'Cardiothoracic Surgery'= '1',
    'Dermatology'= '2',
    'Ear Nose and Throat'= '3',
    'Elderly Medicine'= '4',
    'Gastroenterology'= '5',
    'General Internal Medicine'= '6',
    'General Surgery'= '7',
    'Gynaecology'= '8',
    'Neurology'= '9',
    'Neurosurgical'= '10',
    'Ophthalmology'= '11',
    'Oral Surgery'= '12',
    'Other (total)'= '13',
    'Other - Medical'= '14',
    'Other - Mental Health'= '15',
    'Other - Other'= '16',
    'Other - Paediatric'= '17',
    'Other - Surgical'= '18',
    'Plastic Surgery'= '19',
    'Respiratory Medicine'= '20',
    'Rheumatology'= '21',
    'All specialties (total)'= '22',
    'Orthopaedics'= '23',
    'Urology'= '24',
}
export default TreatmentType;
