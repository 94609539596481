























































import { Component, Vue } from 'vue-property-decorator';
import WaitTimeData from '@/services/waitTimeData';
import TreatmentType from '@/models/treatmentType';
import DataType from '@/models/dataType';
import GeographicLevel from '@/models/geographicLevel';
import CcgCsvData from '@/models/ccgCsvData';
import Rates from '@/components/rates.vue';
import { MapOptions, MapChart } from '@lcp/map-chart';
import InlineRankingsTable from '@/components/inline-rankings-table.vue';
import TreatmentTypeUtil from '@/models/treatmentTypeUtil';

@Component({
    components: {
        MapChart,
        Rates,
        InlineRankingsTable,
    },
})
export default class Home extends Vue {
    loading = false;

    waitTimeData: WaitTimeData | null = null;

    selectedMetric: keyof CcgCsvData = 'totalNumberOfIncompletePathways';

    showChange = false;

    selectedTreatment = TreatmentType['All specialties (total)'];

    mapRef = 'map';

    mapLoaded = false;

    async activated () {
        this.waitTimeData = await WaitTimeData.get();
        this.waitTimeData.selectDate('Nov-21');
    }

    get hoveredArea () {
        return this.waitTimeData?.hoveredArea;
    }

    get hoveredAreaName () {
        return this.waitTimeData?.getAreaNameFromAreaCode(this.hoveredArea || '');
    }

    get treatmentTypes () {
        return TreatmentType;
    }

    get ccgRanking () {
        return this.waitTimeData?.getRankingForCcg(this.waitTimeData.getCcgCodeForAreaCode(this.hoveredArea!, true) || '');
    }

    get ccgCount () {
        return this.waitTimeData?.getCcgRankings.length || 0;
    }

    get selectedMetricName () {
        return this.waitTimeData?.metrics.find((a) => a.value === this.selectedMetric)?.name;
    }

    get mapOptions (): MapOptions {
        return {
            topoJsonSettings: {
                jsonPath: '/ccg6.json',
                featureCollectionName: 'collection',
                areaPropertyName: 'ccg20cd',
            },
            display: {
                gradient: false,
                unselectedColour: 'rgb(144, 144, 144)',
                colourRange: ['#DA291C', '#FAE100', '#78BE20'],
                hiddenAreas: ['Wales', 'Scotland', 'Northen Ireland'],
            },
            areaGroups: {
                hasRegions: false,
            },
            interactive: {
                allowPan: true,
                allowZoom: false,
                allowSelectRegion: false,
                allowSelectArea: false,
            },
            extents: {
                extentsFunction: this.extentFunction,
            },
            events: {
                loading: (loading: boolean) => {
                    this.mapLoaded = !loading;
                    this.waitTimeData!.map = this.$refs[this.mapRef] as MapChart;
                },
                areaHovered: (area: string | null) => {
                    this.waitTimeData!.hoveredArea = area ?? '';
                },
            },
        };
    }

    get hoveredValue () {
        if (!this.hoveredArea || !this.waitTimeData || !this.mapData) return '';
        return this.mapData[this.hoveredArea] ?? '';
    }

    get notMobile () {
        return window.innerWidth > 900;
    }

    get mapHeader () {
        let header = '';
        switch (this.selectedMetric) {
        case 'totalNumberOfIncompletePathways':
            header = 'Total number waiting this month';
            break;
        case 'averageMedianWaitingTimeInWeeks':
            header = 'Average median waiting time this month';
            break;
        case 'totalGreaterThan52Weeks':
            header = 'Total waiting more than 52 weeks';
            break;
        case 'unmetNeeds':
            header = 'Total estimated needs this month';
            break;
        default:
            break;
        }
        if (this.showChange) {
            header += ' compared to the previous month';
        }

        return header;
    }

    formatNumber (number: number) {
        if (!number) return '';
        return (Math.round(number * 100) / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    formatHeaderNumber (number?: number) {
        if (number === undefined) return '';
        return (Math.round(number * 10) / 10).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    metricSelected ({ metric, change }: { metric: keyof CcgCsvData; change?: boolean}) {
        this.selectedMetric = metric;
        this.showChange = change ?? false;
    }

    get dates () {
        if (!this.waitTimeData) return [];
        return this.waitTimeData.dates;
    }

    get latestEstimateDate () {
        if (!this.waitTimeData) return '';
        return this.waitTimeData.dates[this.waitTimeData.dates.length - 1];
    }

    get highestYForMap () {
        return this.waitTimeData?.highestForTypes([this.selectedMetric], this.selectedTreatment, GeographicLevel.CCG);
    }

    extentFunction () {
        if (!this.mapData || !this.waitTimeData) return [0, 0, 0];
        let min = 9999;
        let max = 0;

        let total = 0;
        const vals = Object.values(this.mapData);
        vals.forEach((val) => {
            if (val === null) return;
            total += val;
            if (min > val) min = val;
            if (max < val) max = val;
        });
        if (this.showChange) return [max, 0, min];
        if (this.isHigherBetter) return [min, total / vals.length, this.highestYForMap];
        return [this.highestYForMap, total / vals.length, min];
    }

    get isHigherBetter () {
        if (this.showChange) return false;
        return this.waitTimeData?.metrics.find((a) => a.value === this.selectedMetric)?.higherBetter;
    }

    get selectedDate () {
        if (!this.waitTimeData) return null;
        return this.waitTimeData.selectedDate;
    }

    get mapData () {
        if (!this.waitTimeData) return null;
        if (this.showChange) {
            const latest = this.waitTimeData.getAllAreaDataForDateGroupedByArea(this.selectedDate || this.latestEstimateDate, this.selectedMetric, this.selectedTreatment, DataType.Incomplete) as Record<string, number>;
            const previous = this.waitTimeData.getAllAreaDataForDateGroupedByArea('Oct-21', this.selectedMetric, this.selectedTreatment, DataType.Incomplete) as Record<string, number>;
            const grouped: Record<string, number> = {};
            Object.keys(latest).forEach((key) => {
                grouped[key] = latest[key] - previous[key];
            });
            return grouped;
        }
        return this.waitTimeData.getAllAreaDataForDateGroupedByArea(this.selectedDate || this.latestEstimateDate, this.selectedMetric, this.selectedTreatment, DataType.Incomplete);
    }

    get specialities () {
        const output: { name: string|undefined; y: number|null|undefined; difference: number }[] = [];

        Object.values(TreatmentType).forEach((treatmentType) => {
            if (treatmentType !== '13' && TreatmentTypeUtil.isDrilldownType(treatmentType)) return;
            output.push({
                name: this.waitTimeData?.getTreatmentNameFromKey(treatmentType),
                y: (this.getDataForTreatmentType(treatmentType, 'Nov-21') ?? 0),
                difference: (this.getDataForTreatmentType(treatmentType, 'Nov-21') ?? 0) - (this.getDataForTreatmentType(treatmentType, 'Oct-21') ?? 0),
            });
        });
        return output.filter((a) => a.name !== 'All specialties (total)' && a.name !== 'Other (total)').sort((a, b) => ((a?.y ?? 0) < (b?.y ?? 0) ? 1 : -1)).filter((a, i) => i < 10);
    }

    getDataForTreatmentType (treatmentType: TreatmentType, date: string) {
        return this.waitTimeData?.getNationalTotalForDate(date, 'totalNumberOfIncompletePathways', treatmentType);
    }
}
