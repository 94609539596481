


































































import WaitTimeData from '@/services/waitTimeData';
import {
    Component, Vue,
} from 'vue-property-decorator';
import DataType from '@/models/dataType';
import TreatmentType from '@/models/treatmentType';
import CcgCsvData from '@/models/ccgCsvData';

@Component({
    components: {
    },
})
export default class Rates extends Vue {
        waitTimeData: WaitTimeData | null = null;

        async mounted () {
            this.waitTimeData = await WaitTimeData.get();
        }

        formatHeaderNumber (number?: number) {
            if (number === undefined) return '';
            return (Math.round(number * 10) / 10).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }

        get selectedArea () {
            return this.waitTimeData?.selectedArea || '';
        }

        get selectedRegion () {
            return this.waitTimeData?.selectedArea ? this.waitTimeData.getAreaNameFromAreaCode(this.waitTimeData.selectedArea) : this.waitTimeData?.selectedRegion || '';
        }

        get previousMonth () {
            if (!this.waitTimeData || !this.waitTimeData.loaded) return null;
            const index = this.waitTimeData.dates.indexOf(this.selectedDate || this.latestEstimateDate);
            if (index === 0) return null;
            return this.waitTimeData?.dates[index - 1];
        }

        get selectedDate () {
            return this.latestEstimateDate;
        }

        get selectedTreatment () {
            return this.waitTimeData?.selectedTreament || TreatmentType['All specialties (total)'];
        }

        get latestEstimateDate () {
            if (!this.waitTimeData) return '';
            return 'Nov-21';
        }

        getValue (date: string, metric: keyof CcgCsvData) {
            if (this.selectedArea) {
                return this.waitTimeData!.getAllAreaDataForDateGroupedByCcg(date, metric, this.selectedTreatment, DataType.Incomplete)[this.selectedArea];
            }
            if (this.selectedRegion) {
                return this.waitTimeData!.getAllRegionDataForDateGroupedByRegion(date, metric, this.selectedTreatment, DataType.Incomplete)[this.waitTimeData!.getRegionCodeFromRegionName(this.selectedRegion)];
            }
            return this.waitTimeData!.getNationalTotalForDate(date, metric, this.selectedTreatment, DataType.Incomplete);
        }

        get numberWaiting () {
            if (!this.waitTimeData || !this.waitTimeData.loaded) return 0;
            return this.getValue(this.selectedDate || this.latestEstimateDate, 'totalNumberOfIncompletePathways') || 0;
        }

        get numberWaitingMonthlyChange () {
            if (!this.previousMonth) return 0;
            const previousMonthNumber = this.getValue(this.previousMonth, 'totalNumberOfIncompletePathways');
            if (!previousMonthNumber) return 0;
            return this.numberWaiting - previousMonthNumber;
        }

        get medianWaitTime () {
            if (!this.waitTimeData || !this.waitTimeData.loaded) return 0;
            return this.getValue(this.selectedDate || this.latestEstimateDate, 'averageMedianWaitingTimeInWeeks') || 0;
        }

        get medianWaitTimeMonthlyChange () {
            if (!this.previousMonth) return 0;
            const previousMonthNumber = this.getValue(this.previousMonth, 'averageMedianWaitingTimeInWeeks');
            if (!previousMonthNumber) return 0;
            return this.medianWaitTime - previousMonthNumber;
        }

        get percentGreater () {
            if (!this.waitTimeData || !this.waitTimeData.loaded) return 0;
            return this.getValue(this.selectedDate || this.latestEstimateDate, 'totalGreaterThan52Weeks') || 0;
        }

        get percentGreaterMonthlyChange () {
            if (!this.previousMonth) return 0;
            const previousMonthNumber = this.getValue(this.previousMonth, 'totalGreaterThan52Weeks');
            if (!previousMonthNumber) return 0;
            return ((this.percentGreater - previousMonthNumber) / previousMonthNumber) * 100;
        }

        get unmetNeeds () {
            if (!this.waitTimeData || !this.waitTimeData.loaded) return 0;
            return this.getValue(this.selectedDate || this.latestEstimateDate, 'unmetNeeds') || 0;
        }

        get unmetNeedsMonthlyChange () {
            if (!this.previousMonth) return 0;
            const previousMonthNumber = this.getValue(this.previousMonth, 'unmetNeeds');
            if (!previousMonthNumber) return 0;
            return ((this.percentGreater - previousMonthNumber) / previousMonthNumber) * 100;
        }

        rateClicked ({ metric, change }: { metric: string; change: boolean }) {
            this.$emit('selected', { metric, change });
        }
}
