import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';
import DataAndMethod from '../views/DataAndMethod.vue';
import Terms from '../views/Terms.vue';
import FAQ from '../views/FAQ.vue';
import Insight from '../views/Insight.vue';
import Analysis from '../views/Analysis.vue';
import Dashboard from '../views/Dashboard.vue';
import Drivers from '../views/Drivers.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'Home',
        component: Dashboard,
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Home,
    },
    {
        path: '/drivers',
        name: 'Drivers',
        component: Drivers,
    },
    {
        path: '/dataAndMethod',
        name: 'DataMethod',
        component: DataAndMethod,
    },
    {
        path: '/insight',
        name: 'Insight',
        component: Insight,
    },
    {
        path: '/faq',
        name: 'faq',
        component: FAQ,
    },
    {
        path: '/terms',
        name: 'Terms',
        component: Terms,
    },
    {
        path: '/analysis',
        name: 'Analysis',
        component: Analysis,
    },
    {
        path: '/deprivationAnalysis',
        name: 'deprivationAnalysis',
        component: Analysis,
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

export default router;
