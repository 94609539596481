import TreatmentType from './treatmentType';

export default class TreatmentTypeUtil {
    public static isDrilldownType (treatmentType: TreatmentType) {
        switch (treatmentType) {
        case '13':
        case '14':
        case '15':
        case '16':
        case '17':
        case '18':
            return true;
        default:
            return false;
        }
    }

    public static getAllDrilldownTypes () {
        return Object.keys(TreatmentType).filter((a) => this.isDrilldownType(TreatmentType[a as keyof typeof TreatmentType]));
    }

    public static getKeyFromValue (treatmentType: TreatmentType) {
        return Object.keys(TreatmentType)[Object.values(TreatmentType).indexOf(treatmentType)];
    }
}
