import WaitTimeApi from '@/api/waitTimeApi';
import CcgCsvData from '@/models/ccgCsvData';
import DataType from '@/models/dataType';
import GeographicLevel from '@/models/geographicLevel';
import CcgArea from '@/models/localAuthority';
import Projection from '@/models/projection';
import TreatmentType from '@/models/treatmentType';
import MidpointDisplacement from '@/util/midpointDisplacement';
import { MapChart } from '@lcp/map-chart';
import * as d3 from 'd3';
import dayjs from 'dayjs';
import Vue from 'vue';
import ImdData from './imdData';
// eslint-disable-next-line import/no-cycle

export default class WaitTimeData {
    private static waitTimeData: WaitTimeData;

    newDataDate = new Date(2021, 3);

    statsByRegionAndDate: { [key in DataType ]?: { [regionName: string]: { [key in TreatmentType | string]?: { [date: string]: { [ metric in keyof CcgCsvData ]?: number } } } } } = Vue.observable({});

    statsByAreaAndDate: { [key in DataType ]?: { [areaCode: string]: { [key in TreatmentType | string]?: { [date: string]: { [ metric in keyof CcgCsvData ]?: number } } } } } = Vue.observable({});

    statsByDate: { [key in DataType ]?: { [key in TreatmentType | string]?: { [date: string]: { [ metric in keyof CcgCsvData ]?: number } } } } = Vue.observable({});

    localAuthorities: { [areaCode: string]: CcgArea} = Vue.observable({});

    localAuthoritiesArr: Array<CcgArea> = Vue.observable([]);

    selectedRegion: string | null = Vue.observable(null);

    selectedArea: string | null = Vue.observable(null);

    selectedDate: string | null = Vue.observable(null);

    selectedTreament = Vue.observable(TreatmentType['All specialties (total)']);

    selectedMetric: keyof CcgCsvData = Vue.observable('totalNumberOfIncompletePathways');

    hoveredArea = '';

    loaded = Vue.observable(false);

    loading = Vue.observable(false);

    map?: MapChart;

    selectedSplit = Vue.observable(1);

    regionPopulations: Record<string, number> = {
        Y59: 9180135,
        Y56: 8961989,
        Y62: 7341196,
        Y61: 6236072,
        Y58: 5624696,
        Y60: 4835928 + 5934037,
        Y63: 2669941 + 5502967,
        England: 56286961,
    }

    admissionDrivers = [
        { name: 'Covid admissions per day', min: 100, max: 2000 },
        { name: 'Covid patients in hospital', min: 500, max: 20000 },
        { name: 'Nurses per 100,000 population', min: 700, max: 1000 },
        { name: 'GPs per 100,000 population', min: 50, max: 100 },
        { name: 'Staff absence rate', min: 1, max: 10 },
        { name: 'NHS Spend per head', min: 1500, max: 2800 },
        { name: 'Acute beds per 100,000', min: 1, max: 3 },
    ]

    static async get () {
        if (!this.waitTimeData) {
            this.waitTimeData = new WaitTimeData();
            await this.waitTimeData.initialise();
        }
        return this.waitTimeData;
    }

    async loadTreatmentDataForCCG (metric: keyof CcgCsvData, ccgCode: string, dataType = DataType.Incomplete) {
        if (this.admissionDrivers.find((a) => a.name === metric)) {
            // Generating totally fake data for admission drivers
            const months = this.dates;
            const driver = this.admissionDrivers.find((a) => a.name === metric)!;
            if (!this.statsByAreaAndDate[dataType]![ccgCode]) Vue.set(this.statsByAreaAndDate[dataType]!, ccgCode, {});
            Object.values(TreatmentType).forEach((treatmentType) => {
                if (!this.statsByAreaAndDate[dataType]![ccgCode]![treatmentType as TreatmentType]) Vue.set(this.statsByAreaAndDate[dataType]![ccgCode], treatmentType, {});
                const midpoint = new MidpointDisplacement(months.length);
                midpoint.generateUsingMidPoint(driver.min, driver.max, 0.01);
                const points = midpoint.points;
                months.forEach((monthPeriod, i) => {
                    if (!this.statsByAreaAndDate[dataType]![ccgCode]![treatmentType as TreatmentType]![monthPeriod]) Vue.set(this.statsByAreaAndDate[dataType]![ccgCode]![treatmentType as TreatmentType]!, monthPeriod, {});
                    if (!this.statsByAreaAndDate[dataType]![ccgCode]![treatmentType as TreatmentType]![monthPeriod][metric]) {
                        Vue.set(this.statsByAreaAndDate[dataType]![ccgCode]![treatmentType as TreatmentType]![monthPeriod], metric, points[i]);
                    }
                });
            });
        } else {
            if (!this.statsByAreaAndDate[dataType]) Vue.set(this.statsByAreaAndDate, dataType, {});
            const data = await WaitTimeApi.getAllTreatmentTypesForCcg(metric, ccgCode);
            if (!this.statsByAreaAndDate[dataType]![ccgCode]) Vue.set(this.statsByAreaAndDate[dataType]!, ccgCode, {});
            Object.keys(data).forEach((treatmentType) => {
                if (!this.statsByAreaAndDate[dataType]![ccgCode]![treatmentType as TreatmentType]) Vue.set(this.statsByAreaAndDate[dataType]![ccgCode], treatmentType, {});
                Object.keys(data[treatmentType as TreatmentType]).forEach((monthPeriod) => {
                    if (!this.statsByAreaAndDate[dataType]![ccgCode]![treatmentType as TreatmentType]![monthPeriod]) Vue.set(this.statsByAreaAndDate[dataType]![ccgCode]![treatmentType as TreatmentType]!, monthPeriod, {});
                    Vue.set(this.statsByAreaAndDate[dataType]![ccgCode]![treatmentType as TreatmentType]![monthPeriod], metric, data[treatmentType as TreatmentType][monthPeriod]);
                });
                this.generateFakeData(this.statsByAreaAndDate[dataType]![ccgCode]![treatmentType as TreatmentType]!, metric);
            });
        }
    }

    async loadCcgDataForTreatment (metric: keyof CcgCsvData, treatmentType: TreatmentType, dataType = DataType.Incomplete) {
        if (!this.statsByAreaAndDate[dataType]) Vue.set(this.statsByAreaAndDate, dataType, {});
        if (this.admissionDrivers.find((a) => a.name === metric)) {
            // Generating totally fake data for admission drivers
            const months = this.dates;
            const driver = this.admissionDrivers.find((a) => a.name === metric)!;
            Object.keys(this.localAuthorities).forEach((areaCode) => {
                if (!this.statsByAreaAndDate[dataType]![areaCode]) Vue.set(this.statsByAreaAndDate[dataType]!, areaCode, {});
                if (!this.statsByAreaAndDate[dataType]![areaCode]![treatmentType]) Vue.set(this.statsByAreaAndDate[dataType]![areaCode], treatmentType, {});
                const midpoint = new MidpointDisplacement(months.length);
                midpoint.generateUsingMidPoint(driver.min, driver.max, 0.01);
                const points = midpoint.points;
                months.forEach((monthPeriod, i) => {
                    if (!this.statsByAreaAndDate[dataType]![areaCode]![treatmentType]![monthPeriod]) Vue.set(this.statsByAreaAndDate[dataType]![areaCode]![treatmentType]!, monthPeriod, {});
                    if (!this.statsByAreaAndDate[dataType]![areaCode]![treatmentType]![monthPeriod][metric]) {
                        Vue.set(this.statsByAreaAndDate[dataType]![areaCode]![treatmentType]![monthPeriod], metric, points[i]);
                    }
                });
            });
        } else {
            const data = await WaitTimeApi.getCcgDataForTreatment(metric, treatmentType);
            Object.keys(data).forEach((areaCode) => {
                if (!this.statsByAreaAndDate[dataType]![areaCode]) Vue.set(this.statsByAreaAndDate[dataType]!, areaCode, {});
                if (!this.statsByAreaAndDate[dataType]![areaCode]![treatmentType]) Vue.set(this.statsByAreaAndDate[dataType]![areaCode], treatmentType, {});
                Object.keys(data[areaCode]).forEach((monthPeriod) => {
                    if (!this.statsByAreaAndDate[dataType]![areaCode]![treatmentType]![monthPeriod]) Vue.set(this.statsByAreaAndDate[dataType]![areaCode]![treatmentType]!, monthPeriod, {});
                    Vue.set(this.statsByAreaAndDate[dataType]![areaCode]![treatmentType]![monthPeriod], metric, data[areaCode][monthPeriod]);
                });
            });
        }
    }

    async loadRegionalData (metric: keyof CcgCsvData, dataType = DataType.Incomplete) {
        if (!this.statsByRegionAndDate[dataType]) Vue.set(this.statsByRegionAndDate, dataType, {});
        if (this.admissionDrivers.find((a) => a.name === metric)) {
            // Generating totally fake data for admission drivers
            const months = this.dates;
            const driver = this.admissionDrivers.find((a) => a.name === metric)!;
            this.regionCodes.forEach((regionName) => {
                if (!this.statsByRegionAndDate[dataType]![regionName]) Vue.set(this.statsByRegionAndDate[dataType]!, regionName, {});
                Object.values(TreatmentType).forEach((treatmentType) => {
                    if (!this.statsByRegionAndDate[dataType]![regionName][treatmentType]) Vue.set(this.statsByRegionAndDate[dataType]![regionName], treatmentType, {});
                    const midpoint = new MidpointDisplacement(months.length);
                    midpoint.generateUsingMidPoint(driver.min, driver.max, 0.1);
                    const points = midpoint.points;
                    months.forEach((month, i) => {
                        if (!this.statsByRegionAndDate[dataType]![regionName][treatmentType]![month]) Vue.set(this.statsByRegionAndDate[dataType]![regionName][treatmentType]!, month, {});
                        if (!this.statsByRegionAndDate[dataType]![regionName][treatmentType]![month][metric]) {
                            Vue.set(this.statsByRegionAndDate[dataType]![regionName][treatmentType]![month], metric, points[i]);
                        }
                    });
                });
            });
        } else {
            const data = await WaitTimeApi.getRegionalData(metric);
            Object.keys(data).forEach((areaCode) => {
                if (!this.statsByRegionAndDate[dataType]![areaCode]) Vue.set(this.statsByRegionAndDate[dataType]!, areaCode, {});
                Object.keys(data[areaCode]).forEach((treatmentType) => {
                    if (!this.statsByRegionAndDate[dataType]![areaCode][treatmentType as TreatmentType]) Vue.set(this.statsByRegionAndDate[dataType]![areaCode], treatmentType as TreatmentType, {});
                    Object.keys(data[areaCode][treatmentType as TreatmentType]).forEach((monthPeriod) => {
                        if (!this.statsByRegionAndDate[dataType]![areaCode][treatmentType as TreatmentType]![monthPeriod]) Vue.set(this.statsByRegionAndDate[dataType]![areaCode][treatmentType as TreatmentType]!, monthPeriod, {});
                        Vue.set(this.statsByRegionAndDate[dataType]![areaCode][treatmentType as TreatmentType]![monthPeriod], metric, data[areaCode][treatmentType as TreatmentType][monthPeriod]);
                    });
                    this.generateFakeData(this.statsByRegionAndDate[dataType]![areaCode]![treatmentType as TreatmentType]!, metric);
                });
            });
        }
    }

    async loadNationalData (metric: keyof CcgCsvData, dataType = DataType.Incomplete) {
        if (!this.statsByDate[dataType]) Vue.set(this.statsByDate, dataType, {});
        if (this.admissionDrivers.find((a) => a.name === metric)) {
            // Generating totally fake data for admission drivers
            const months = this.dates;
            const driver = this.admissionDrivers.find((a) => a.name === metric)!;
            Object.values(TreatmentType).forEach((treatmentType) => {
                if (!this.statsByDate[dataType]![treatmentType]) Vue.set(this.statsByDate[dataType]!, treatmentType, {});
                const midpoint = new MidpointDisplacement(months.length);
                midpoint.generateUsingMidPoint(driver.min, driver.max, 0.01);
                const points = midpoint.points;
                months.forEach((month, i) => {
                    if (!this.statsByDate[dataType]![treatmentType]![month]) Vue.set(this.statsByDate[dataType]![treatmentType]!, month, {});
                    if (!this.statsByDate[dataType]![treatmentType]![month][metric]) {
                        Vue.set(this.statsByDate[dataType]![treatmentType]![month], metric, points[i]);
                    }
                });
            });
        } else {
            const data = await WaitTimeApi.getNationalData(metric);

            Object.keys(data).forEach((treatmentType) => {
                if (!this.statsByDate[dataType]![treatmentType as TreatmentType]) Vue.set(this.statsByDate[dataType]!, treatmentType as TreatmentType, {});
                Object.keys(data[treatmentType as TreatmentType]).forEach((monthPeriod) => {
                    if (!this.statsByDate[dataType]![treatmentType as TreatmentType]![monthPeriod]) Vue.set(this.statsByDate[dataType]![treatmentType as TreatmentType]!, monthPeriod, {});
                    Vue.set(this.statsByDate[dataType]![treatmentType as TreatmentType]![monthPeriod], metric, data[treatmentType as TreatmentType][monthPeriod]);
                });

                this.generateFakeData(this.statsByDate[dataType]![treatmentType as TreatmentType]!, metric);
            });
        }
    }

    async loadUnmetNeeds () {
        await d3.csv('/UnmetNeeds.csv').then((result) => {
            const data = result as unknown as Array<Projection>;
            data.forEach((speciality) => {
                Object.keys(speciality).forEach((key) => {
                    if (key === '' || key === 'speciality' || key === 'waitlist' || key === 'proportions') return;
                    const treatmentType = this.getTreatmentKeyFromName(speciality.speciality);
                    if (!this.statsByDate['Incomplete Wait Times']![treatmentType]![key]) {
                        Vue.set(this.statsByDate['Incomplete Wait Times']![treatmentType]!, key, {});
                    }
                    if (!this.statsByDate['Incomplete Wait Times']![treatmentType]![key]!.unmetNeeds) {
                        Vue.set(this.statsByDate['Incomplete Wait Times']![treatmentType]![key], 'unmetNeeds', Number(speciality[key]));
                    }
                    this.ccgCodes.forEach((areaCode) => {
                        const populationProportion = this.localAuthorities[areaCode]?.proportion;
                        if (!this.statsByAreaAndDate!['Incomplete Wait Times']![areaCode]) {
                            return;
                        }
                        if (!this.statsByAreaAndDate!['Incomplete Wait Times']![areaCode]![treatmentType]) {
                            Vue.set(this.statsByAreaAndDate['Incomplete Wait Times']![areaCode], treatmentType, {});
                        }
                        if (!this.statsByAreaAndDate!['Incomplete Wait Times']![areaCode]![treatmentType]![key]) {
                            Vue.set(this.statsByAreaAndDate['Incomplete Wait Times']![areaCode]![treatmentType]!, key, {});
                        }
                        if (!this.statsByAreaAndDate['Incomplete Wait Times']![areaCode]![treatmentType]![key]!.unmetNeeds) {
                            Vue.set(this.statsByAreaAndDate['Incomplete Wait Times']![areaCode]![treatmentType]![key], 'unmetNeeds', Number(speciality[key]) * populationProportion);
                        }
                    });
                    this.regionCodes.forEach((areaCode) => {
                        const populationProportion = this.regionPopulations[areaCode] / this.regionPopulations.England;
                        if (!this.statsByRegionAndDate!['Incomplete Wait Times']![areaCode]) {
                            return;
                        }
                        if (!this.statsByRegionAndDate!['Incomplete Wait Times']![areaCode]![treatmentType]) {
                            Vue.set(this.statsByRegionAndDate['Incomplete Wait Times']![areaCode], treatmentType, {});
                        }
                        if (!this.statsByRegionAndDate!['Incomplete Wait Times']![areaCode]![treatmentType]![key]) {
                            Vue.set(this.statsByRegionAndDate['Incomplete Wait Times']![areaCode]![treatmentType]!, key, {});
                        }
                        if (!this.statsByRegionAndDate['Incomplete Wait Times']![areaCode]![treatmentType]![key]!.unmetNeeds) {
                            Vue.set(this.statsByRegionAndDate['Incomplete Wait Times']![areaCode]![treatmentType]![key], 'unmetNeeds', Number(speciality[key]) * populationProportion);
                        }
                    });
                });
            });
        });
    }

    async loadProjections () {
        await d3.csv('/Projections.csv').then((result) => {
            const data = result as unknown as Array<Projection>;
            data.forEach((speciality) => {
                Object.keys(speciality).forEach((key) => {
                    if (key === '' || key === 'speciality' || key === 'waitlist' || key === 'proportions') return;
                    const treatmentType = this.getTreatmentKeyFromName(speciality.speciality);
                    if (!this.statsByDate['Incomplete Wait Times']![treatmentType]![key]) {
                        Vue.set(this.statsByDate['Incomplete Wait Times']![treatmentType]!, key, {});
                    }
                    if (!this.statsByDate['Incomplete Wait Times']![treatmentType]![key]!.totalNumberOfIncompletePathways) {
                        Vue.set(this.statsByDate['Incomplete Wait Times']![treatmentType]![key], 'totalNumberOfIncompletePathways', Number(speciality[key]));
                    }
                    this.ccgCodes.forEach((areaCode) => {
                        const populationProportion = this.localAuthorities[areaCode]?.proportion;
                        if (!this.statsByAreaAndDate!['Incomplete Wait Times']![areaCode]) {
                            return;
                        }
                        if (!this.statsByAreaAndDate!['Incomplete Wait Times']![areaCode]![treatmentType]) {
                            Vue.set(this.statsByAreaAndDate['Incomplete Wait Times']![areaCode], treatmentType, {});
                        }
                        if (!this.statsByAreaAndDate!['Incomplete Wait Times']![areaCode]![treatmentType]![key]) {
                            Vue.set(this.statsByAreaAndDate['Incomplete Wait Times']![areaCode]![treatmentType]!, key, {});
                        }
                        if (!this.statsByAreaAndDate['Incomplete Wait Times']![areaCode]![treatmentType]![key]!.totalNumberOfIncompletePathways) {
                            Vue.set(this.statsByAreaAndDate['Incomplete Wait Times']![areaCode]![treatmentType]![key], 'totalNumberOfIncompletePathways', Number(speciality[key]) * populationProportion);
                        }
                    });
                    this.regionCodes.forEach((areaCode) => {
                        const populationProportion = this.regionPopulations[areaCode] / this.regionPopulations.England;
                        if (!this.statsByRegionAndDate!['Incomplete Wait Times']![areaCode]) {
                            return;
                        }
                        if (!this.statsByRegionAndDate!['Incomplete Wait Times']![areaCode]![treatmentType]) {
                            Vue.set(this.statsByRegionAndDate['Incomplete Wait Times']![areaCode], treatmentType, {});
                        }
                        if (!this.statsByRegionAndDate!['Incomplete Wait Times']![areaCode]![treatmentType]![key]) {
                            Vue.set(this.statsByRegionAndDate['Incomplete Wait Times']![areaCode]![treatmentType]!, key, {});
                        }
                        if (!this.statsByRegionAndDate['Incomplete Wait Times']![areaCode]![treatmentType]![key]!.totalNumberOfIncompletePathways) {
                            Vue.set(this.statsByRegionAndDate['Incomplete Wait Times']![areaCode]![treatmentType]![key], 'totalNumberOfIncompletePathways', Number(speciality[key]) * populationProportion);
                        }
                    });
                });
            });
        });
    }

    generateFakeData (obj: Record<string, any>, metric: string) {
        if (metric === 'totalNumberOfIncompletePathways' || metric === 'unmetNeeds') return;
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const years = ['21', '22', '23', '24', '25'];
        const midpoint = new MidpointDisplacement(months.length * years.length);
        // eslint-disable-next-line no-restricted-globals
        const existing = Object.values(obj).map((a) => a[metric]).filter((a) => a && !isNaN(a));
        const min = Math.max(...existing);
        midpoint.generateUsingMidPoint(min, min * 1.4, 0.01);
        const points = midpoint.points;
        for (let year = 0; year < years.length; year += 1) {
            for (let month = 0; month < months.length; month += 1) {
                if (!obj[`${months[month]}-${years[year]}`]) Vue.set(obj, `${months[month]}-${years[year]}`, {});
                if (!obj[`${months[month]}-${years[year]}`][metric]) {
                    Vue.set(obj[`${months[month]}-${years[year]}`], metric, points[year * 12 + month]);
                }
            }
        }
    }

    async loadLatestData (dataType = DataType.Incomplete) {
        const data = await WaitTimeApi.getLatestData();
        const metrics: Array<string> = [];
        Object.keys(data.CCG).forEach((areaCode) => {
            if (!this.statsByAreaAndDate[dataType]![areaCode]) Vue.set(this.statsByAreaAndDate[dataType]!, areaCode, {});
            Object.keys(data.CCG[areaCode]).forEach((treatmentType) => {
                if (!this.statsByAreaAndDate[dataType]![areaCode]![treatmentType as TreatmentType]) Vue.set(this.statsByAreaAndDate[dataType]![areaCode], treatmentType, {});
                Object.keys(data.CCG[areaCode][treatmentType as TreatmentType]).forEach((monthPeriod) => {
                    if (!this.statsByAreaAndDate[dataType]![areaCode]![treatmentType as TreatmentType]![monthPeriod]) Vue.set(this.statsByAreaAndDate[dataType]![areaCode]![treatmentType as TreatmentType]!, monthPeriod, {});
                    Object.keys(data.CCG[areaCode][treatmentType as TreatmentType][monthPeriod]).forEach((metric) => {
                        metrics.push(metric);
                        Vue.set(this.statsByAreaAndDate[dataType]![areaCode]![treatmentType as TreatmentType]![monthPeriod], metric, data.CCG[areaCode][treatmentType as TreatmentType][monthPeriod][metric as keyof CcgCsvData]);
                    });
                });
                [...new Set(metrics)].forEach((metric) => {
                    this.generateFakeData(this.statsByAreaAndDate[dataType]![areaCode][treatmentType as TreatmentType]!, metric);
                });
            });
        });
    }

    async loadHeadlineData (treamtentType: TreatmentType, dataType = DataType.Incomplete) {
        const data = await WaitTimeApi.getHeadlineData(treamtentType);
        const metrics: Array<string> = [];
        Object.keys(data.CCG).forEach((areaCode) => {
            if (!this.statsByAreaAndDate[dataType]![areaCode]) Vue.set(this.statsByAreaAndDate[dataType]!, areaCode, {});
            if (!this.statsByAreaAndDate[dataType]![areaCode]![treamtentType]!) Vue.set(this.statsByAreaAndDate[dataType]![areaCode], treamtentType, {});
            Object.keys(data.CCG[areaCode]).forEach((monthPeriod) => {
                if (!this.statsByAreaAndDate[dataType]![areaCode]![treamtentType]![monthPeriod]) Vue.set(this.statsByAreaAndDate[dataType]![areaCode]![treamtentType]!, monthPeriod, {});
                Object.keys(data.CCG[areaCode][monthPeriod]).forEach((metric) => {
                    metrics.push(metric);
                    Vue.set(this.statsByAreaAndDate[dataType]![areaCode]![treamtentType]![monthPeriod], metric, data.CCG[areaCode][monthPeriod][metric as keyof CcgCsvData]);
                });
            });
            [...new Set(metrics)].forEach((metric) => {
                this.generateFakeData(this.statsByAreaAndDate[dataType]![areaCode]![treamtentType as TreatmentType]!, metric);
            });
        });
        Object.keys(data.Regional).forEach((areaCode) => {
            if (!this.statsByRegionAndDate[dataType]![areaCode]) Vue.set(this.statsByRegionAndDate[dataType]!, areaCode, {});
            if (!this.statsByRegionAndDate[dataType]![areaCode]![treamtentType]!) Vue.set(this.statsByRegionAndDate[dataType]![areaCode], treamtentType, {});
            Object.keys(data.Regional[areaCode]).forEach((monthPeriod) => {
                if (!this.statsByRegionAndDate[dataType]![areaCode]![treamtentType]![monthPeriod]) Vue.set(this.statsByRegionAndDate[dataType]![areaCode]![treamtentType]!, monthPeriod, {});
                Object.keys(data.Regional[areaCode][monthPeriod]).forEach((metric) => {
                    Vue.set(this.statsByRegionAndDate[dataType]![areaCode]![treamtentType]![monthPeriod], metric, data.Regional[areaCode][monthPeriod][metric as keyof CcgCsvData]);
                });
            });
            [...new Set(metrics)].forEach((metric) => {
                this.generateFakeData(this.statsByRegionAndDate[dataType]![areaCode]![treamtentType as TreatmentType]!, metric);
            });
        });
        Object.values(data.National).forEach((area) => {
            if (!this.statsByDate[dataType]![treamtentType]!) Vue.set(this.statsByDate[dataType]!, treamtentType, {});
            Object.keys(area).forEach((monthPeriod) => {
                if (!this.statsByDate[dataType]![treamtentType]![monthPeriod]) Vue.set(this.statsByDate[dataType]![treamtentType]!, monthPeriod, {});
                Object.keys(area[monthPeriod]).forEach((metric) => {
                    Vue.set(this.statsByDate[dataType]![treamtentType]![monthPeriod], metric, area[monthPeriod][metric as keyof CcgCsvData]);
                });
            });
            [...new Set(metrics)].forEach((metric) => {
                this.generateFakeData(this.statsByDate[dataType]![treamtentType as TreatmentType]!, metric);
            });
        });
    }

    async initialise () {
        await d3.csv('/CCG_Mappings.csv').then((result) => {
            const data = result as unknown as Array<CcgArea>;
            data.forEach((la) => {
                Vue.set(this.localAuthorities, la.ccgCode, la);
                Vue.set(this.localAuthorities, la.areaCode, la);
                if (!this.localAuthorities[la.newCcgCode]) {
                    Vue.set(this.localAuthorities, la.newCcgCode, la);
                }
                this.localAuthoritiesArr.push(la);
            });
        });
        this.loading = true;
        await this.loadNationalData(this.selectedMetric);
        await this.loadRegionalData(this.selectedMetric);
        await this.loadCcgDataForTreatment(this.selectedMetric, TreatmentType['All specialties (total)']);
        await this.loadHeadlineData(TreatmentType['All specialties (total)']);
        await this.loadProjections();
        await this.loadUnmetNeeds();
        this.loaded = true;
        this.loading = false;
        this.selectDate('Nov-21');
    }

    getTreatmentNameFromKey (treatment: TreatmentType) {
        return Object.keys(TreatmentType).find((a) => TreatmentType[a as keyof typeof TreatmentType] === treatment);
    }

    getTreatmentKeyFromName (treatment: string) {
        return TreatmentType[Object.keys(TreatmentType).find((a) => a === treatment) as keyof typeof TreatmentType];
    }

    selectRegion (region: string | null) {
        this.selectedRegion = region;
    }

    selectArea (area: string | null) {
        const ccgCode = this.getCcgCodeForAreaCode(area);
        if (ccgCode && ccgCode !== this.selectedArea) {
            this.loadTreatmentDataForCCG(this.selectedMetric, ccgCode);
        }
        this.selectedArea = ccgCode;
    }

    getCcgCodeForAreaCode (areaCode: string | null, dateDependantCcg = true) {
        if (!areaCode) return null;
        const val = this.localAuthorities[areaCode];
        return (val?.newCcgCode && dateDependantCcg && this.selectedDateIsAfterRegionChange ? val?.newCcgCode : val?.ccgCode) || null;
    }

    parseDate (date: string) {
        const split = date?.split('-');
        if (!split || split.length < 2) return dayjs();
        return dayjs(`${split[0]}-20${split[1]}`, 'MMM-YYYY');
    }

    dateAfterNewDataDate (dateString: string) {
        const date = this.parseDate(dateString);
        return date.isAfter(this.newDataDate) || date.isSame(this.newDataDate, 'day');
    }

    get selectedDateIsAfterRegionChange () {
        return this.dateAfterNewDataDate(this.selectedDate!);
    }

    get selectedAreaCodes () {
        if (!this.selectedArea) return [];
        return this.localAuthoritiesArr.filter((a) => a.newCcgCode === this.selectedArea || a.ccgCode === this.selectedArea).map((a) => a.areaCode);
    }

    getGroupedAreaCodes (areaCode: string) {
        const newCcg = this.localAuthorities[areaCode].newCcgCode;
        if (!this.selectedDateIsAfterRegionChange || !newCcg) return [areaCode];
        return this.localAuthoritiesArr.filter((a) => a.newCcgCode === newCcg).map((a) => a.areaCode);
    }

    selectTreatment (treatmentType: TreatmentType) {
        this.selectedTreament = treatmentType;
        this.loadCcgDataForTreatment(this.selectedMetric, treatmentType);
        this.loadHeadlineData(treatmentType);
        this.loading = false;
    }

    selectMetric (metric: keyof CcgCsvData = 'totalNumberOfIncompletePathways') {
        if (metric !== this.selectedMetric && metric !== null) {
            this.loading = true;
            this.loadCcgDataForTreatment(metric, this.selectedTreament);
            this.loadRegionalData(metric);
            this.loadNationalData(metric);
            if (this.selectedArea) {
                this.loadTreatmentDataForCCG(metric, this.localAuthorities[this.selectedArea].ccgCode);
            }
            this.loading = false;
        }
        this.selectedMetric = metric;
    }

    selectDate (date: string) {
        if (date !== undefined) {
            this.selectedDate = date;
        }
    }

    get regionNames () {
        return this.localAuthoritiesArr
            .map((a) => a.nhsRegionName)
            .filter((val, index, self) => self.indexOf(val) === index);
    }

    get regionCodes () {
        return this.localAuthoritiesArr
            .map((a) => a.regionCode)
            .filter((val, index, self) => self.indexOf(val) === index);
    }

    getAllAreasForRegion (regionName: string) {
        const areas = [];
        const las = this.localAuthoritiesArr;
        const date = dayjs(this.newDataDate);
        areas.push(...las
            .filter((a) => a.nhsRegionName === regionName)
            .map((a) => ({
                areaCode: a.areaCode,
                value: a.ccgCode,
                name: a.ccgName,
            })));
        areas.push(...las
            .filter((a) => a.nhsRegionName === regionName && a.newCcgCode)
            .map((a) => ({
                areaCode: '',
                areaCodes: las.filter((b) => b.newCcgCode === a.newCcgCode),
                value: a.newCcgCode,
                name: `${a.newCcgName} (${date.format('MMM-YY')})`,
            })));

        return Object.values(this.groupBy(areas, 'value')).map((a) => a[0]);
    }

    get areaCodes () {
        return [...new Set(this.localAuthoritiesArr.map((a) => a.areaCode))];
    }

    get ccgCodes () {
        const output = this.localAuthoritiesArr
            .map((a) => a.ccgCode);

        if (this.dateAfterNewDataDate(this.dates[this.dates.length - 1])) {
            const newCCgs = this.localAuthoritiesArr
                .map((a) => a.newCcgCode)
                .filter((val, index, self) => self.indexOf(val) === index);

            output.push(...newCCgs);
        }

        return output;
    }

    get newCcgCodes () {
        return this.localAuthoritiesArr
            .map((a) => a.newCcgCode)
            .filter((val, index, self) => self.indexOf(val) === index);
    }

    get dates () {
        if (!this.loaded) return [];
        if (this.selectedArea) {
            return this.datesForCcg(this.selectedArea);
        }
        return this.allDates;
    }

    get allDates () {
        const output = Object.keys(this.statsByDate[DataType.Incomplete]![this.selectedTreament]!).sort((a, b) => (this.parseDate(a) > this.parseDate(b) ? 1 : -1));

        if (this.additionalTotalMetrics.filter((x) => x.value === this.selectedMetric).length === 0) return output;
        return output.filter((x) => this.dateAfterNewDataDate(x));
    }

    datesForCcg (ccgCode: string, additionalMetricCheck = true) {
        const output = Object.keys(this.statsByAreaAndDate[DataType.Incomplete]![ccgCode]![this.selectedTreament]! || {}).sort((a, b) => (this.parseDate(a) > this.parseDate(b) ? 1 : -1));

        if (!additionalMetricCheck || this.additionalTotalMetrics.filter((x) => x.value === this.selectedMetric).length === 0) return output;
        return output.filter((x) => this.dateAfterNewDataDate(x));
    }

    getMetrics (treatmentType: TreatmentType) {
        const output = [
            {
                name: 'Number waiting', value: 'totalNumberOfIncompletePathways', icon: 'calendar-times', color: '#f27174', total: true, description: 'The total number of pathways waiting to start treatment at the end of the month.',
            },
            {
                name: 'Number waiting per 100,000', value: 'totalIncompletePathwaysPer100000Population', icon: 'calendar-times', color: '#f27174', total: true, description: 'The total number of pathways per 100,000 population waiting to start treatment.',
            },
            {
                name: 'Average (median) waiting times in weeks', value: 'averageMedianWaitingTimeInWeeks', icon: 'stopwatch', description: 'The time (in weeks) that 50% of patients waited less than and 50% waited more than.',
            },
            {
                name: 'Highest wait times (92nd percentile) in weeks', value: 'ninetySecondPercentileWaitingTimeInWeeks', icon: 'stopwatch', description: 'The time (in weeks) that 92% of pathways waited less than to start treatment and 8% of pathways waited more than.',
            },
            {
                name: 'Number waiting within 18 weeks', value: 'totalWithin18Weeks', icon: 'calendar-check', color: '#faa743', total: true, description: 'The total number of pathways waiting within the 18 week standard to start treatment.',
            },
            {
                name: 'Number waiting within 18 weeks per 100,000', value: 'totalWithin18WeeksPer100000Population', icon: 'calendar-check', color: '#faa743', higherBetter: false, description: 'The total number of pathways per 100,000 population waiting within the 18 week standard to start treatment.',
            },
            {
                name: '% waiting within 18 weeks', value: 'percentWithin18Weeks', icon: 'calendar-check', color: '#faa743', description: 'The percentage of pathways within 18 weeks of referral.',
            },
            {
                name: 'Number waiting more than 18 weeks', value: 'totalGreaterThan18Weeks', icon: 'calendar-week', color: '#7b9fa5', total: true, description: 'The total number of pathways waiting more than 18 weeks to start treatment.',
            },
            {
                name: 'Number waiting more than 18 weeks per 100,000', value: 'totalGreaterThan18WeeksPer100000Population', icon: 'calendar-week', color: '#7b9fa5', total: true, description: 'The total number of pathways per 100,000 population waiting more than 18 weeks to start treatment.',
            },
            {
                name: '% waiting more than 18 weeks', value: 'percentGreaterThan18Weeks', icon: 'calendar-week', color: '#7b9fa5', description: 'The percentage of pathways waiting more than 18 weeks since referral.',
            },
            {
                name: 'Number waiting more than 52 weeks', value: 'totalGreaterThan52Weeks', icon: 'calendar-alt', color: '#005c5f', total: true, description: 'The total number of pathways waiting more than 52 weeks to start treatment.',
            },
            {
                name: 'Number waiting more than 52 weeks per 100,000', value: 'totalGreaterThan52WeeksPer100000Population', icon: 'calendar-alt', color: '#005c5f', total: true, description: 'The total number of pathways per 100,000 population waiting more than 52 weeks to start treatment.',
            },
            {
                name: '% waiting more than 52 weeks', value: 'percentGreaterThan52Weeks', icon: 'calendar-alt', color: '#005c5f', description: 'The percentage of pathways waiting more than 52 weeks since referral.',
            }];

        if (treatmentType === TreatmentType['All specialties (total)']) {
            output.push(...this.additionalTotalMetrics);
        }

        return output;
    }

    get metrics () {
        return this.getMetrics(this.selectedTreament);
    }

    get additionalTotalMetrics () {
        return [
            {
                name: 'Number waiting more than 78 weeks (18 months)', value: 'totalGreaterThan78Weeks', icon: 'calendar-week', color: '#7b9fa5', total: true, description: 'The total number of pathways waiting more than 18 months to start treatment.',
            },
            {
                name: 'Number waiting more than 78 weeks (18 months) per 100,000', value: 'totalGreaterThan78WeeksPer100000Population', icon: 'calendar-week', color: '#7b9fa5', total: true, description: 'The total number of pathways per 100,000 population waiting more than 18 months to start treatment.',
            },
            {
                name: '% waiting more than 78 weeks (18 months)', value: 'percentGreaterThan78Weeks', icon: 'calendar-week', color: '#7b9fa5', description: 'The percentage of pathways waiting more than 18 months since referral.',
            },
            {
                name: 'Number waiting more than 104 weeks (2 years)', value: 'totalGreaterThan104Weeks', icon: 'calendar-alt', color: '#005c5f', total: true, description: 'The total number of pathways waiting more than 2 years to start treatment.',
            },
            {
                name: 'Number waiting more than 104 weeks (2 years) per 100,000', value: 'totalGreaterThan104WeeksPer100000Population', icon: 'calendar-alt', color: '#005c5f', total: true, description: 'The total number of pathways per 100,000 population waiting more than 2 years to start treatment.',
            },
            {
                name: '% waiting more than 104 weeks (2 years)', value: 'percentGreaterThan104Weeks', icon: 'calendar-alt', color: '#005c5f', description: 'The percentage of pathways waiting more than 2 years since referral.',
            }];
    }

    get ranking (): number {
        if (!this.selectedArea) return this.getRankingForRegion(this.selectedRegion!);
        return this.getRankingForCcg(this.selectedArea);
    }

    get rankings () {
        if (!this.selectedArea) {
            return this.getRegionRankings;
        }

        return this.getCcgRankings;
    }

    get getRegionRankings () {
        const areas = this.getAllRegionDataForDateGroupedByRegion(this.selectedDate || '', this.selectedMetric, this.selectedTreament, DataType.Incomplete);
        const sorted = Object.keys(areas).filter((a) => a !== '-' && areas[a] !== null).map((a) => ({ area: a, value: areas[a] }));
        const grouped = this.groupBy<{ area: string; value: number }>(sorted as Array<{[key: string]: number | string }>, 'value');
        const ranks: Array<{ area: string; rank: number; value: number }> = [];
        const keys = Object.keys(grouped).sort((a, b) => (Number(a) > Number(b) ? 1 : -1));
        if (this.isHigherBetter) keys.reverse();
        let extraCount = 0;
        keys.forEach((key, i) => {
            grouped[key].forEach((area) => { ranks.push({ ...area, rank: i + extraCount }); });
            extraCount += grouped[key].length - 1;
        });
        return ranks;
    }

    get getCcgRankings () {
        const areas = this.getAllAreaDataForDateGroupedByCcg(this.selectedDate || '', this.selectedMetric, this.selectedTreament, DataType.Incomplete, true);
        const sorted = Object.keys(areas).filter((a) => areas[a] !== null).map((a) => ({ area: a, value: areas[a] }));
        const grouped = this.groupBy<{ area: string; value: number }>(sorted as Array<{[key: string]: number | string }>, 'value');
        const ranks: Array<{ area: string; rank: number; value: number }> = [];
        const keys = Object.keys(grouped).sort((a, b) => (Number(a) > Number(b) ? 1 : -1));
        if (this.isHigherBetter) keys.reverse();
        let extraCount = 0;
        keys.forEach((key, i) => {
            grouped[key].forEach((area) => { ranks.push({ ...area, rank: i + extraCount }); });
            extraCount += grouped[key].length - 1;
        });
        return ranks;
    }

    get isHigherBetter () {
        return this.metrics.find((a) => a.value === this.selectedMetric)?.higherBetter;
    }

    groupBy<T> (array: Array<{ [key: string]: string | number }>, key: string): { [key: string]: Array<T> } {
        return array.reduce((r, a) => {
            r[a[key]] = r[a[key]] || [];
            r[a[key]].push(a);
            return r;
        }, Object.create(null));
    }

    getRankingForCcg (areaCode: string) {
        const val = this.getCcgRankings.find((a) => a.area === areaCode);
        return val ? val.rank : -1;
    }

    getRankingForRegion (regionName: string) {
        const regionCode = this.getRegionCodeFromRegionName(regionName);
        const val = this.getRegionRankings.find((a) => a.area === regionCode);
        return val ? val.rank : -1;
    }

    highestForTypes (props: Array<keyof CcgCsvData>, treatmentType: TreatmentType, level: GeographicLevel) {
        let highest = 0;
        if (level === GeographicLevel.CCG) {
            Object.values(this.statsByAreaAndDate['Incomplete Wait Times']! || {}).forEach((area) => {
                Object.values((area[treatmentType] || {})).forEach((val) => {
                    props.forEach((metric) => {
                        if (val && (val[metric] || 0) > highest) highest = val[metric] || highest;
                    });
                });
            });
        }
        return highest;
    }

    highestForSelected (types: Array<TreatmentType> | null = null) {
        let highest = 0;
        const typesToUse = types ?? Object.values(TreatmentType);

        if (this.selectedArea) {
            typesToUse.forEach((treatmentType) => {
                Object.values(this.statsByAreaAndDate['Incomplete Wait Times']![this.selectedArea!][treatmentType] || {}).forEach((date) => {
                    if (date && (date[this.selectedMetric] || 0) > highest) highest = date[this.selectedMetric] || highest;
                });
            });
        } else if (this.selectedRegion) {
            const regionCode = this.getRegionCodeFromRegionName(this.selectedRegion);
            typesToUse.forEach((treatmentType) => {
                Object.values(this.statsByRegionAndDate['Incomplete Wait Times']![regionCode!][treatmentType] || {}).forEach((date) => {
                    if (date && (date[this.selectedMetric] || 0) > highest) highest = date[this.selectedMetric] || highest;
                });
            });
        } else {
            typesToUse.forEach((treatmentType) => {
                Object.values(this.statsByDate['Incomplete Wait Times']![treatmentType] || {}).forEach((date) => {
                    if (date && (date[this.selectedMetric] || 0) > highest) highest = date[this.selectedMetric] || highest;
                });
            });
        }

        return highest;
    }

    getRegionNameFromCode (areaCode: string) {
        return this.localAuthorities[areaCode]?.nhsRegionName || '';
    }

    getRegionNameFromRegionCode (regionCode: string) {
        return this.localAuthoritiesArr.find((a) => a.regionCode === regionCode)?.nhsRegionName || regionCode;
    }

    getRegionCodeFromRegionName (regionName: string) {
        return this.localAuthoritiesArr.find((a) => a.nhsRegionName === regionName)?.regionCode || regionName;
    }

    getAreaNameFromAreaCode (areaCode: string, dateDependantCcg = true) {
        if (!dateDependantCcg || !this.selectedDateIsAfterRegionChange) {
            return this.localAuthorities[areaCode]?.ccgName || '';
        }
        const val = this.localAuthorities[areaCode];
        return val?.newCcgName || val?.ccgName;
    }

    getAreaNameFromCcgCode (ccgCode: string, getNewName = false) {
        const val = Object.values(this.localAuthorities).find((a) => (a.newCcgCode === ccgCode || a.ccgCode === ccgCode));
        return this.selectedDateIsAfterRegionChange || getNewName ? val?.newCcgName || val?.ccgName : (val?.ccgName || '');
    }

    getareaCodesFromCcgCode (ccgCode: string) {
        return this.localAuthoritiesArr.filter((a) => a.ccgCode === ccgCode || a.newCcgCode === ccgCode).map((a) => a.areaCode) || [];
    }

    getAllAreasInRegionForDate (regionName: string) {
        const arr = [];
        if (this.selectedDateIsAfterRegionChange) {
            arr.push(...this.localAuthoritiesArr.filter((a) => a.nhsRegionName === regionName && a.newCcgCode === '').map((a) => a.ccgCode));
            arr.push(...this.localAuthoritiesArr.filter((a) => a.nhsRegionName === regionName && a.newCcgCode !== '').map((a) => a.newCcgCode));
        } else {
            arr.push(...this.localAuthoritiesArr.filter((a) => a.nhsRegionName === regionName).map((a) => a.ccgCode));
        }

        return [...new Set(arr)];
    }

    getNewCcgForCcg (ccgCode: string) {
        return this.localAuthorities[ccgCode].newCcgCode;
    }

    getAllRegionDataForDateGroupedByRegion (date: string, prop: keyof CcgCsvData, treatmentType: TreatmentType, type: DataType = DataType.Incomplete) {
        const data: { [regionName: string]: number | null } = {};
        const regions = Object.keys(this.statsByRegionAndDate[type]!);
        regions.forEach((regionCode) => {
            if (!this.statsByRegionAndDate[type]![regionCode]![treatmentType]) {
                console.log(date, prop, treatmentType, regionCode);
            }
            const row = this.statsByRegionAndDate[type]![regionCode]![treatmentType]![date];
            data[regionCode] = row === undefined ? null : row[prop] || null;
        });
        return data;
    }

    getAllAreaDataForDateGroupedByArea (date: string, prop: keyof CcgCsvData, treatmentType: TreatmentType, type: DataType = DataType.Incomplete) {
        const data: { [areCode: string]: number | null } = {};
        this.areaCodes.forEach((areaCode) => {
            const ccgCode = this.getCcgCodeForAreaCode(areaCode);
            if (!ccgCode || !this.statsByAreaAndDate[type]?.[ccgCode]?.[treatmentType]) {
                data[areaCode] = null;
                return;
            }
            data[areaCode] = this.statsByAreaAndDate[type]![ccgCode]![treatmentType]![date] ? ((this.statsByAreaAndDate[type]![ccgCode]![treatmentType]![date][prop] ?? 0) * (this.selectedSplit + Math.random() * 0.01)) ?? null : null;
        });
        return data;
    }

    getAllAreaDataForDateGroupedByCcg (date: string, prop: keyof CcgCsvData, treatmentType: TreatmentType, type: DataType = DataType.Incomplete, dateDependantCcg = false) {
        const data: { [ccgCode: string]: number | null } = {};
        this.ccgCodes.forEach((ccgCode) => {
            if (!this.statsByAreaAndDate[type]?.[ccgCode]?.[treatmentType]) {
                data[ccgCode] = null;
                return;
            }
            if (dateDependantCcg) {
                if (this.selectedDateIsAfterRegionChange && this.localAuthorities[ccgCode]?.newCcgCode && this.localAuthorities[ccgCode]?.newCcgCode !== ccgCode) {
                    return;
                }
                if (!this.selectedDateIsAfterRegionChange && this.newCcgCodes.indexOf(ccgCode) > -1) return;
            }
            data[ccgCode] = this.statsByAreaAndDate[type]![ccgCode]![treatmentType]![date] ? this.statsByAreaAndDate[type]![ccgCode]![treatmentType]![date][prop] ?? null : null;
        });
        return data;
    }

    getNationalTotalForDate (date: string, prop: keyof CcgCsvData, treatmentFunction: TreatmentType, type: DataType = DataType.Incomplete) {
        if (!this.statsByDate[type]![treatmentFunction]![date]) return null;
        return this.statsByDate[type]![treatmentFunction]![date][prop] || null;
    }

    getTotalsForAllDatesForArea (ccgCode: string, prop: keyof CcgCsvData, treatmentFunction: TreatmentType, type: DataType = DataType.Incomplete, dateDependantCcg = false) {
        if (dateDependantCcg) {
            return this.allDates.map((date) => (this.statsByAreaAndDate[type]![ccgCode]![treatmentFunction] && this.statsByAreaAndDate[type]![ccgCode]![treatmentFunction]![date] ? this.statsByAreaAndDate[type]![ccgCode]![treatmentFunction]![date]![prop] : 0));
        }
        return this.dates.map((date) => (this.statsByAreaAndDate[type]![ccgCode]![treatmentFunction] && this.statsByAreaAndDate[type]![ccgCode]![treatmentFunction]![date] ? this.statsByAreaAndDate[type]![ccgCode]![treatmentFunction]![date]![prop] : 0));
    }

    getTotalsForAllDatesForRegion (regionName: string, prop: keyof CcgCsvData, treatmentFunction: TreatmentType, type: DataType = DataType.Incomplete) {
        const ccgCode = this.getRegionCodeFromRegionName(regionName);
        return this.dates.map((date) => (this.statsByRegionAndDate[type]![ccgCode]![treatmentFunction]![date] ? this.statsByRegionAndDate[type]![ccgCode]![treatmentFunction]![date]![prop] : 0));
    }
}
