/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import MapChart from '@lcp/map-chart';
import {
    faHome, faMapMarkedAlt, faExpandAlt, faChartScatter, faTriangle, faInfo, faBars,
    faHistory, faAsterisk, faFileAlt, faQuestionCircle, faInfoCircle, faEnvelope, faTimes, faSearch, faUserMd,
    faPlus, faMinus, faCaretDown, faChevronRight, faChevronLeft, faNewspaper, faVirus, faPercent, faUsers, faMoneyBillWave, faBadgePercent, faAngleDoubleLeft, faAngleDoubleRight, faUserFriends, faChild, faTombstone, faTombstoneAlt, faCalendarTimes, faUserClock, faCalendarAlt, faCalendarWeek, faCalendarCheck, faStopwatch, faLocation, faTable, faHospital, faChartPie, faChartBar, faTachometer, faArrowLeft,
} from '@fortawesome/pro-duotone-svg-icons';

import { faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';

import HighchartsVue from 'highcharts-vue';
import Highcharts, { Chart, XAxisCrosshairLabelFormatterCallbackFunction } from 'highcharts';
import annotations from 'highcharts/modules/annotations';
import drilldown from 'highcharts/modules/drilldown';

import { faUserAltSlash } from '@fortawesome/free-solid-svg-icons';
import App from './App.vue';
import router from './router';
import clickOutside from './directives/click-outside';
import './directives/focus';

Vue.use(MapChart);

Highcharts.Pointer.prototype.reset = function disableMouseOut () {
    const newLocal: { chart: Chart } = this as unknown as { chart: Chart };
    newLocal.chart.tooltip.hide();
    return undefined;
};

Highcharts.wrap(Highcharts.Axis.prototype, 'drawCrosshair', function drawCrosshair (this: { cross: any; options: { clickOnCrosshair: Function }; chart: Highcharts.Chart }, proceed) {
    const axis = this as { cross: any; options: { clickOnCrosshair: Function }; chart: Highcharts.Chart };
    const wasRendered = !!axis.cross;
    // eslint-disable-next-line prefer-rest-params
    proceed.apply(axis, Array.prototype.slice.call(arguments, 1));
    if (!wasRendered && axis.cross && axis.options.clickOnCrosshair) {
        axis.cross.css({
            pointerEvents: 'auto',
            cursor: 'pointer',
        });

        axis.cross.on('click', (e: XAxisCrosshairLabelFormatterCallbackFunction) => {
            const point = axis.chart.hoverPoint;
            axis.options.clickOnCrosshair(e, point);
        });
    }
});

annotations(Highcharts);
drilldown(Highcharts);
Vue.use(HighchartsVue, { Highcharts });

clickOutside(Vue);
library.add(faHome, faMapMarkedAlt, faExpandAlt, faChartScatter, faTriangle, faInfo, faBars, faAsterisk, faCaretDown, faUserFriends,
    faHistory, faFileAlt, faQuestionCircle, faInfoCircle, faEnvelope, faTimes, faTwitter, faLinkedin, faPlus, faMinus, faAngleDoubleLeft, faAngleDoubleRight, faUserAltSlash,
    faCalendarTimes, faUserClock, faCalendarAlt, faCalendarWeek, faCalendarCheck, faStopwatch, faLocation, faSearch, faMapMarkedAlt, faTable,
    faChevronRight, faChevronLeft, faNewspaper, faChartScatter, faVirus, faPercent, faUsers, faMoneyBillWave, faBadgePercent, faChild, faTombstone, faTombstoneAlt,
    faHospital, faUserMd, faChartPie, faChartBar, faTachometer, faArrowLeft);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.config.productionTip = false;

new Vue({
    router,
    render: (h) => h(App),
}).$mount('#app');
