
















































































import { Component, Vue, Watch } from 'vue-property-decorator';
import RankingsTable from '@/components/rankings-table.vue';
import InlineRankingsTable from '@/components/inline-rankings-table.vue';
import { GradientType } from '@/models/gradient';
import WaitTimeData from '@/services/waitTimeData';
import LineChart from '@/components/chart/highcharts-line-chart.vue';
import Rates from '@/components/rates.vue';
import dayjs from 'dayjs';
import dropdown from '@/components/dropdown.vue';
import TreatmentType from '@/models/treatmentType';
import DataType from '@/models/dataType';
import GeographicLevel from '@/models/geographicLevel';
import CcgCsvData from '@/models/ccgCsvData';
import SpecialitiesChart from '@/components/chart/specialities-chart.vue';
import Postcodes from '@/services/postcodes';
import { MapChart, MapOptions } from '@lcp/map-chart';

@Component({
    components: {
        dropdown,
        LineChart,
        SpecialitiesChart,
        Rates,
        RankingsTable,
        InlineRankingsTable,
    },
})
export default class Drivers extends Vue {
    waitTimeData: WaitTimeData | null = null;

    lineChart: LineChart = new LineChart();

    showIntro = false;

    change = 'Daily';

    postcodeSearch = '';

    dismissPostcodes = true;

    showRanks = false;

    postcodes: Postcodes | null = null;

    showingMap = true;

    mapLoaded = false;

    resized = false;

    hoveredMetric = '';

    splitList = [
        { name: 'All', value: 'All' },
        { name: 'Waiting for operations', value: 'Waiting for operations' },
        { name: 'Waiting for investigations', value: 'Waiting for investigations' },
        { name: 'Waiting for outpatient appointments', value: 'Waiting for outpatient appointments' },
    ]

    mapRef = 'map';

    created () {
        window.onresize = () => { this.resized = true; };
    }

    beforeDestroy () {
        window.onresize = null;
    }

    async activated () {
        if (window.innerWidth < 900) {
            this.showIntro = false;
            this.showingMap = false;
        }
        this.waitTimeData = await WaitTimeData.get();
        this.postcodes = await Postcodes.get();
        this.selectedMetric = 'Staff absence rate';
        this.waitTimeData.selectRegion(null);
        this.waitTimeData.selectArea(null);
        this.$nextTick(() => {
            this.waitTimeData!.selectDate('Nov-21');
        });
        this.mapRef = `map${Math.random()}`;
    }

    get route () {
        return this.$route.path;
    }

    getLocation () {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                if (this.mapData && this.postcodes) {
                    const area = (this.$refs[this.mapRef] as MapChart).getAreaByLatLong(position.coords.latitude, position.coords.longitude);
                    if (area) {
                        const ccgCode = this.waitTimeData!.getCcgCodeForAreaCode(area, false);
                        this.postcodeSearch = ccgCode ? this.waitTimeData?.localAuthorities[ccgCode]?.ccgName || '' : '';
                    }
                }
            });
        }
    }

    get loading () {
        return this.waitTimeData?.loading;
    }

    areaName (ccgCode: string) {
        return this.waitTimeData?.getAreaNameFromCcgCode(ccgCode);
    }

    async selectArea (ccgCode: string) {
        const datesForCcg = this.waitTimeData!.datesForCcg(ccgCode);
        if (this.selectedDate && datesForCcg.indexOf(this.selectedDate) === -1) {
            this.waitTimeData!.selectDate('Nov-21');
        }
        (this.$refs[this.mapRef] as MapChart).selectAreas(this.waitTimeData!.getareaCodesFromCcgCode(ccgCode));
    }

    get mapOptions (): MapOptions {
        return {
            topoJsonSettings: {
                jsonPath: '/ccg6.json',
                featureCollectionName: 'collection',
                areaPropertyName: 'ccg20cd',
            },
            display: {
                gradient: false,
                unselectedColour: 'rgb(144, 144, 144)',
                colourRange: ['#DA291C', '#FAE100', '#78BE20'],
                hiddenAreas: ['Wales', 'Scotland', 'Northen Ireland'],
            },
            areaGroups: {
                hasRegions: true,
                getRegionForArea: (area: string) => this.waitTimeData!.getRegionNameFromCode(area),
            },
            interactive: {
                allowPan: true,
                allowZoom: false,
                disabledAreas: ['Scotland', 'Wales', 'Northen Ireland'],
            },
            extents: {
                extentsFunction: this.extentFunction,
            },
            events: {
                loading: (loading: boolean) => {
                    this.mapLoaded = !loading;
                    this.waitTimeData!.map = this.$refs[this.mapRef] as MapChart;
                },
                areaSelected: (area: string[]) => {
                    this.waitTimeData!.selectArea(area.length ? area[0] : null);
                },
                regionSelected: (region: string[]) => {
                    this.waitTimeData!.selectRegion(region.length ? region[0] : null);
                },
                areaHovered: (area: string | null) => {
                    this.waitTimeData!.hoveredArea = area ?? '';
                },
            },
        };
    }

    get postcodeAreas () {
        if (this.postcodeSearch?.length < 2) return [];
        const areasFromPostCode = this.postcodes?.getAreaCodeFromPostcode(this.postcodeSearch).slice(0, 10);
        if (!areasFromPostCode) return [];

        const output: Array<{ postcode: string; ccgCode: string; ccgName: string }> = [];
        const newCodes = this.waitTimeData!.newCcgCodes;
        const date = dayjs(this.waitTimeData!.newDataDate);
        if (this.waitTimeData?.dateAfterNewDataDate(this.latestEstimateDate)) {
            if (this.postcodeSearch.indexOf('CCG') > -1 && areasFromPostCode.length === 1) {
                const newCcg = this.waitTimeData.getNewCcgForCcg(areasFromPostCode[0].ccgCode);
                if (newCcg) {
                    const displayName = `${this.waitTimeData.getAreaNameFromCcgCode(newCcg, true)} (${date.format('MMM-YY')})`;
                    output.push({ postcode: areasFromPostCode[0].postcode, ccgCode: newCcg, ccgName: displayName });
                }
            }
            areasFromPostCode.forEach((element) => {
                if (newCodes.filter((a) => a === element.ccgCode).length > 0) {
                    if (output.filter((a) => a.ccgCode === element.ccgCode).length > 0) return;

                    const displayName = `${element.ccgName} (${date.format('MMM-YY')})`;
                    output.push({ postcode: element.postcode, ccgCode: element.ccgCode, ccgName: displayName });
                } else {
                    output.push(element);
                }
            });
        } else {
            areasFromPostCode.forEach((element) => {
                if (newCodes.filter((a) => a === element.ccgCode).length > 0) return;
                output.push(element);
            });
        }

        return output;
    }

    extentFunction () {
        if (!this.mapData || !this.waitTimeData) return [0, 0, 0];
        let min = 9999;
        let max = 0;

        let total = 0;
        const vals = Object.values(this.mapData);
        vals.forEach((val) => {
            if (val === null) return;
            total += val;
            if (min > val) min = val;
            if (max < val) max = val;
        });
        if (this.isHigherBetter) return [min, total / vals.length, this.highestYForMap];
        return [this.highestYForMap, total / vals.length, min];
    }

    get isHigherBetter () {
        return this.waitTimeData?.metrics.find((a) => a.value === this.waitTimeData?.selectedMetric)?.higherBetter;
    }

    get tooltip () {
        return this.$refs.mapTooltip;
    }

    get hoveredArea () {
        return this.waitTimeData?.hoveredArea;
    }

    get hoveredAreaName () {
        return this.waitTimeData?.getAreaNameFromAreaCode(this.hoveredArea || '');
    }

    get ccgRanking () {
        return this.waitTimeData?.getRankingForCcg(this.waitTimeData.getCcgCodeForAreaCode(this.hoveredArea!, true) || '');
    }

    get ccgCount () {
        return this.waitTimeData?.getCcgRankings.length || 0;
    }

    get treatmentTypes () {
        return TreatmentType;
    }

    get selectedMetric () {
        return (this.waitTimeData?.selectedMetric || 'totalNumberOfIncompletePathways') as keyof CcgCsvData;
    }

    set selectedMetric (metric: string) {
        if (this.$route.name === 'Drivers') {
            this.waitTimeData!.selectMetric(metric as keyof CcgCsvData);
        }
    }

    get selectedMetricName () {
        return this.waitTimeData?.metrics.find((a) => a.value === this.selectedMetric)?.name;
    }

    get regionsList () {
        return [
            { name: 'England', value: '', children: [] },
            ...this.waitTimeData?.regionNames.sort((a, b) => (a > b ? 1 : -1)).map((a) => ({
                name: a,
                value: a,
                children:
            Object.values(this.waitTimeData?.getAllAreasForRegion(a) || []),
            })) || []];
    }

    get hoveredValue () {
        if (!this.hoveredArea || !this.waitTimeData) return '';
        return this.waitTimeData.getAllAreaDataForDateGroupedByArea(
            this.selectedDate || this.dates[this.dates.length - 1],
            this.selectedMetric as keyof CcgCsvData,
            this.selectedTreatment,
            DataType.Incomplete,
        )[this.hoveredArea];
    }

    get metrics () {
        return this.waitTimeData?.admissionDrivers || [];
    }

    get notMobile () {
        return window.innerWidth > 900;
    }

    hoveredMetricChanged (metric: string) {
        this.hoveredMetric = metric;
    }

    formatNumber (number: number) {
        if (number === undefined) return '';
        return (Math.round(number * 100) / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    formatHeaderNumber (number?: number) {
        if (number === undefined) return '';
        return (Math.round(number * 10) / 10).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    get dates () {
        if (!this.waitTimeData) return [];
        return this.waitTimeData.dates;
    }

    get latestEstimateDate () {
        return 'Nov-21';
        // if (!this.waitTimeData?.loaded) return '';
        // return this.waitTimeData.dates[this.waitTimeData.dates.length - 1];
    }

    get selectedTreatment () {
        return this.waitTimeData?.selectedTreament || TreatmentType['All specialties (total)'];
    }

    get selectedTreatmentName () {
        return this.waitTimeData?.getTreatmentNameFromKey(this.selectedTreatment);
    }

    get highestY () {
        let geographicLevel;

        if (this.selectedArea) {
            geographicLevel = GeographicLevel.CCG;
        } else if (this.selectedRegion) {
            geographicLevel = GeographicLevel.Regional;
        } else {
            geographicLevel = GeographicLevel.National;
        }

        return this.waitTimeData?.highestForTypes([this.selectedMetric as keyof CcgCsvData], this.selectedTreatment, geographicLevel);
    }

    get highestYForMap () {
        return this.waitTimeData?.highestForTypes([this.selectedMetric as keyof CcgCsvData], this.selectedTreatment, GeographicLevel.CCG);
    }

    get selectedAreaName () {
        if (!this.selectedArea) return '';
        return this.waitTimeData?.getAreaNameFromCcgCode(this.selectedArea);
    }

    formatDate (date: string) {
        return dayjs(date).format('DD MMM YYYY');
    }

    changeAreaFromChart (ccgCode: string) {
        const datesForCcg = this.waitTimeData!.datesForCcg(ccgCode);
        this.waitTimeData!.selectDate('Nov-21');
        this.selectedRegion = ccgCode;
    }

    get axisTitle () {
        switch (this.selectedMetric) {
        case 'averageMedianWaitingTimeInWeeks':
            return 'Median waiting time';

        case 'totalGreaterThan52Weeks':
            return 'Total greater than 52 weeks';

        default:
            return this.selectedMetric;
        }
    }

    get selectedDate () {
        if (!this.waitTimeData) return null;
        return this.waitTimeData.selectedDate;
    }

    get selectedDateInfo () {
        if (!this.waitTimeData || !this.selectedDate) return [];

        return [
            {
                name: this.selectedMetric,
                value: this.waitTimeData.getNationalTotalForDate(
                    this.selectedDate || this.dates[this.dates.length - 1],
                    this.selectedMetric as keyof CcgCsvData,
                    this.selectedTreatment,
                    DataType.Incomplete,
                ),
            },
        ];
    }

    get selectedArea () {
        return this.waitTimeData?.selectedArea || '';
    }

    get selectedRegion () {
        return this.waitTimeData?.selectedArea || this.waitTimeData?.selectedRegion || '';
    }

    set selectedRegion (val: string) {
        if (!this.waitTimeData) return;
        if (val === this.waitTimeData.selectedArea) {
            return;
        }
        if (val === this.waitTimeData.selectedRegion && this.selectedArea) {
            (this.$refs[this.mapRef] as MapChart).deselect();
        }
        if (this.$refs[this.mapRef]) {
            if (!val) {
                this.selectEngland();
                return;
            }
            const isRegion = this.regionsList.find((a) => a.value === val);
            if (!isRegion) {
                const datesForCcg = this.waitTimeData.datesForCcg(val, false);
                if (this.selectedDate && datesForCcg.indexOf(this.selectedDate) === -1) {
                    this.waitTimeData.selectDate('Nov-21');
                }
                (this.$refs[this.mapRef] as MapChart).selectAreas(this.waitTimeData.getareaCodesFromCcgCode(val));
            } else {
                (this.$refs[this.mapRef] as MapChart).selectRegions([val]);
            }
        } else {
            this.waitTimeData.selectRegion(val);
        }
    }

    selectEngland () {
        if (this.waitTimeData) {
            (this.$refs[this.mapRef] as MapChart).deselect();
            this.waitTimeData.selectRegion(null);
        }
    }

    get mapData () {
        if (!this.waitTimeData || this.waitTimeData.loading) return null;
        return this.waitTimeData.getAllAreaDataForDateGroupedByArea(this.selectedDate || this.latestEstimateDate, this.selectedMetric as keyof CcgCsvData, this.selectedTreatment, DataType.Incomplete);
    }

    get gradientType () {
        return GradientType.NoGradient;
    }

    @Watch('selectedRegion')
    selectedRegionChanged () {
        if (!this.selectedRegion) this.showRanks = false;
    }
}
